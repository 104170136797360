import { createSelector } from 'reselect'

export const provincesSelector = createSelector(
    [state => state.area],
    area => area.provinces
)

export const areasSelector = createSelector(
    [state => state.area],
    area => area.areas
)

export const universitiesSelector = createSelector(
    [state => state.area],
    area => area.universities
)

export const facultiesSelector = createSelector(
    [state => state.area],
    area => area.faculties
)

export const jobsSelector = createSelector(
    [state => state.area],
    area => area.jobs
)