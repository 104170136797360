import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { RatingScale } from '../../constants/masterData';
import BaseTable from '../common/table/BaseTable';
import { AppConstants } from '../../constants';
import logo from '../../assets/images/badge.png';
import entrepreneurBadge from '../../assets/images/entrepreneur-badge.png';
import Utils from '../../utils';
import { convertStringToDateTimeString } from '../../utils/datetimeHelper';
import { getCompetencesColumns } from '../../constants/masterData';

function DigitalProfileContent({
    data,
    listLoading,
    userData,
    handlePrintDocument,
    exportPDFLoading,
}) {
    const dataList = useMemo(() => {
        let temp = data?.data && data.data.reduce((r, a) => {
                r[a.workFieldId] = {
                    workFieldId: a.workFieldId,
                    workFieldName: a.workFieldName,
                    imagePath: a.imagePath,
                    competences: [
                        ...r[a.workFieldId]?.competences || [], 
                        {
                            competenceId: a.competenceId,
                            competenceName: a.competenceName,
                            maxScore: a.maxScore,
                            score: a.score,
                        }
                    ]
                }
                return r;
               }, []) || {};
        return Object.values(temp);
    }, [data])

    const columns = useMemo(() => {
        return [
            {
                title: 'Lĩnh vực',
                width: '30%',
                className: 'work-field',
                render: (dataRow) => (<div className="area" style={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    <img 
                        className="imagePath" 
                        src={ dataRow?.imagePath ? `${AppConstants.contentRootUrl}/${dataRow.imagePath}` : logo}
                    />
                    <div className="name">{dataRow.workFieldName}</div>
                    </div>
                ),
            },
            {
                title: (
                <div className="title-th">
                    <div className="title-competence" style={{flex: 1}}>Năng lực</div>
                    <div className="title-score" style={{width: 100}}>Cơ bản</div>
                    <div className="title-score" style={{width: 110}}>Trung bình</div>
                    <div className="title-score" style={{width: 100}}>Nâng cao</div>
                </div>),
                dataIndex: 'competences',
                className: "col-competences",
                width: '70%',
                render: (competences, dataRow, index) => {
                    const competencesColumns = getCompetencesColumns(index)
                    return (
                        <Table
                            className={`competences competences--${index}`}
                            columns={competencesColumns}
                            rowKey={(record) => record.competenceId}
                            dataSource={competences || []}
                            pagination={false}
                            showHeader={false}
                        />
                    )
                }
            },
        ];
    }, [dataList])

    const render = (
        <div>
            <div className="title"> Hồ sơ năng lực số</div>
            <div className="download-cert">
                <span onClick={() => {handlePrintDocument(dataList)}} style={{
                    pointerEvents: exportPDFLoading ? 'none' : 'all',
                    opacity: exportPDFLoading ? 0.5 : 1,
                }}>
                        <DownloadOutlined /> Tải chứng chỉ tại đây
                </span>
            </div>
            <div>
                <div>
                    <BaseTable 
                        className="base-table digital-profile-table"
                        size="default"
                        scroll={{ x: 800 }}
                        loading={listLoading}
                        columns={columns}
                        rowKey={(record) => record.workFieldId}
                        dataSource={dataList || []}
                        pagination={false}
                        rowClassName="digital-profile-rowtb"
                    />
                </div>
            </div>
        </div>
    )

    return render;
}

export default DigitalProfileContent
