import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import CompetencesPage from '../../components/competences/CompetencesPage'
import MiniProfile from '../../components/competences/MiniProfile'
import qs from 'query-string'
import { userDataSelector } from '../../selectors/account'
import Utils from '../../utils/index'

const strParams = params => {
    return qs.stringify(params)
}

const Competences = () => {
    const history = useHistory();
    const userData = useSelector(userDataSelector)
    const { activeKey } = qs.parse(history.location.search);;
    const pathname = history.location.pathname;
    const handleSelectTab = (activeKey) => {
        history.push(`${pathname}?${strParams({ activeKey })}`);
    }
    return (
        <div className="competences-container">
            <MiniProfile
                fullName={userData.fullName}
                avatar={Utils.getFileUrl(userData.avatarPath)}
                email={userData.email}
            />
            <CompetencesPage 
                handleSelectTab={handleSelectTab}
                activeKey={activeKey || "1"}
            />
        </div>
    )
}

export default Competences
