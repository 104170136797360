import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Card, Pagination } from 'antd';
import { EyeFilled, QuestionCircleFilled, CheckOutlined } from '@ant-design/icons';
import BaseTable from '../common/table/BaseTable';
import { AppConstants } from '../../constants';
import logo from '../../assets/images/badge.png';
import Utils from '../../utils';
import { convertStringToDateTimeString } from '../../utils/datetimeHelper';

const { Meta } = Card;

function SurveyProfileContent({
    data,
    listLoading,
    handleViewDetail,
    // pagination,
    // handlePaginationChange
}) {

    const history = useHistory();
    const pathname = history.location.pathname;
    const dataList = data?.data;
    return (
        <div>
            <div className="title">Hồ sơ đang có</div>
            
            <div className="grid">
                {
                    dataList && dataList.map(d => {
                        return (
                            <Card
                                onClick={() => { handleViewDetail(d) }}
                                key={d.id}
                                hoverable
                                className="card"
                                cover={<img src={d.surveyImage ? `${AppConstants.contentRootUrl}/${d.surveyImage}` : logo} />}
                            >
                                <Meta title={d.surveyName} description={d.surveyDescription} />
                            </Card>
                        )
                    })
                }
            </div>
            {/* <Pagination 
                {...pagination}
                total={data?.totalElements || 10}
                onChange={handlePaginationChange}
            /> */}
        </div>
    )
}

export default SurveyProfileContent
