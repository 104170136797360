import React, { useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'
import { Spin } from 'antd'
import { actions } from '../../actions/competences'
import PerformedTestContent from '../../components/competences/PerformedTestContent'
import PerformedTestDetail from '../../components/competences/PerformedTestDetail'
import { performedTestStackSelector } from '../../selectors/competences'
import { showErrorMessage } from '../../services/notifyService'
import { DEFAULT_TABLE_ITEM_SIZE } from '../../constants'


const strParams = params => {
    return qs.stringify(params)
}

export default function PerformedTest() {
    const dispatch = useDispatch();
    const history = useHistory();
    const pathname = history.location.pathname;
    const { examId , page, createDate, workFieldName, surveyName, isFinish } = qs.parse(history.location.search);
    const key = history.location.key;
    let action = history.action;
    const performedTestStack = useSelector(performedTestStackSelector);
    const [pageData, setPageData] = useState(undefined);
    const [ listLoading, setListLoading ] = useState(true);
    const pagination = {
        current: Number(page) || 1,
        pageSize: 10
    };

    useEffect(()=>{
        const page = findPageInStack(key);
        if (page) {
            setPageData(page);
            setListLoading(false);
            return;
        }
        const index = decideIndexInStack(key);
        getData(index);
    }, [key])

    const getData = (index) => {
        if(examId === undefined){
            setListLoading(true);
            dispatch(
                actions.getPerformedTest({
                    filter: {
                        page: pagination.current - 1,
                        size: pagination.pageSize,
                    },
                    params: {
                        stack:
                            index > -1
                                ? performedTestStack.slice(0, index + 1)
                                : performedTestStack,
                        key,
                        action,
                    },
                    onCompleted: responseData => {
                        setListLoading(false)
                        setPageData(responseData)
                    },
                    onError: error => {
                        setListLoading(false)
                        showErrorMessage(
                            error?.message ||
                                'Đã xảy ra lỗi trong quá trình lấy dữ liệu!'
                        )
                    },
                })
            );
        }
        else {
            setListLoading(true);
            dispatch(
                actions.getPerformedTestDetail({
                    filter: {
                        id: examId,
                        page: pagination.current - 1,
                        size: pagination.pageSize,
                    },
                    params: {
                        stack:
                        index > -1
                            ? performedTestStack.slice(0, index + 1)
                            : performedTestStack,
                        key,
                        action,
                        createDate, 
                        workFieldName, 
                        surveyName,
                        isFinish,
                    },
                    onCompleted: responseData => {
                        setListLoading(false)
                        setPageData(responseData)
                    },
                    onError: error => {
                        setListLoading(false)
                        showErrorMessage(
                            error?.message ||
                                'Đã xảy ra lỗi trong quá trình lấy dữ liệu!'
                        )
                    },
                })
            );
        }
    }

    const findPageInStack = key => {
        return performedTestStack && performedTestStack.find(p => p.key === key)
    }

    const decideIndexInStack = key => {
        if (!performedTestStack) return -1
        const index = performedTestStack.findIndex(p => p.key === key)
        if (index > -1) return index
        if (action === 'POP') return -1
        return performedTestStack.findIndex(p => p.key === pageData?.key)
    }

    const handleTableChange = (pagination) => {
        const newPagination = {
            page: pagination.current,
        }
        if(examId) newPagination.examId = examId;
        history.push(`${pathname}?${strParams({ ...newPagination })}`);
    }

    const handleViewDetail = (data) => {
        data.examId = data.id;
        history.push(`${pathname}?${strParams({ ...data })}`);
    }

    const handleBack = () => {
        history.goBack();
    }

    return (
        <div className="performed-test-container">
            {
                examId ? (
                    <PerformedTestDetail 
                        dataDetail={pageData || {}}
                        pagination={pagination}
                        listLoading={listLoading}
                        handleBack={handleBack}
                        handleTableChange={handleTableChange}
                    />
                )
                : pageData?.isFinish === undefined ? (
                    <PerformedTestContent 
                        dataList={pageData?.data || []}
                        listLoading={listLoading}
                        handleTableChange={handleTableChange}
                        pagination={pagination}
                        handleViewDetail={handleViewDetail}
                        total={pageData?.totalElements || DEFAULT_TABLE_ITEM_SIZE}
                    />
                ) : <Spin spinning={true}></Spin>
            }
        </div>
    )
}
