const baseHeader = {
    'Content-Type': 'application/json',
    Accept: '*/*',
}

const multipartFormHeader = {
    'Content-Type': 'multipart/form-data',
}

const apiConfig = {
    file: {
        upload: {
            path: '/v1/file/upload',
            method: 'POST',
            headers: multipartFormHeader,
        },
    },
    account: {
        login: {
            path: '/v1/account/login',
            method: 'POST',
            headers: baseHeader,
        },
        logout: {
            path: '/v1/account/logout',
            method: 'GET',
            headers: baseHeader,
        },
        verifyAccount: {
            path: '/v1/account/verify_account',
            method: 'POST',
            headers: baseHeader,
        },
        requestForgotPassword: {
            path: '/v1/account/request_forget_password',
            method: 'POST',
            headers: baseHeader,
        },
        changePassword: {
            path: '/v1/account/forget_password',
            method: 'POST',
            headers: baseHeader,
        },
    },
    customer: {
        getProfile: {
            path: '/v1/customer/get-profile',
            method: 'GET',
            headers: baseHeader,
        },
        register: {
            path: '/v1/customer/register',
            method: 'POST',
            headers: baseHeader,
        },
        updateProfile: {
            path: '/v1/customer/update-profile',
            method: 'PUT',
            headers: baseHeader,
        },
    },
    area: {
        listCombobox: {
            path: '/v1/province/list_combobox',
            method: 'GET',
            headers: baseHeader,
        },
        categoryListCombobox: {
            path: '/v1/category/list_combobox',
            method: 'GET',
            headers: baseHeader,
        },
    },
    aboutUs: {
        categoriesCombobox: {
            path: '/v1/category/list_combobox',
            method: 'GET',
            headers: baseHeader,
        },
        listNews: {
            path: '/v1/news/list_news',
            method: 'GET',
            headers: baseHeader,
        },
        getById: {
            path: '/v1/news/get_news',
            method: 'GET',
            headers: baseHeader,
        }
    },
    module: {
        moduleCombobox: {
            path: '/v1/competences/list_module',
            method: 'GET',
            headers: baseHeader,
        },
    },
    test: {
        listCompetenceByModuleCombobox: {
            path: '/v1/competences/list_competence_by_module',
            method: 'GET',
            headers: baseHeader,
        },
        listQuestionByCompetenceCombobox: {
            path: '/v1/competences/list_question_by_competence',
            method: 'GET',
            headers: baseHeader,
        },
        examAnswer:{
            path: '/v1/exam/answer',
            method: 'POST',
            headers: baseHeader,
        },
        finishExam:{
            path: '/v1/exam/finish',
            method: 'PUT',
            headers: baseHeader,
        },
    },
    competences: {
        getPerformedTestList: {
            path: '/v1/exam/list-exam',
            method: 'GET',
            headers: baseHeader,
        },
        getPerformedTestDetail: {
            path: '/v1/exam/details',
            method: 'GET',
            headers: baseHeader,
        },
        getSurveyProfileList: {
            path: '/v1/survey_profile/list-client',
            method: 'GET',
            headers: baseHeader,
        },
        getCompetenceProfileList: {
            path: '/v1/competences/competence_profile',
            method: 'GET',
            headers: baseHeader,
        },
        getDigitalProfileList: {
            path: '/v1/competences/digital_profile',
            method: 'GET',
            headers: baseHeader,
        }
    },
}

export default apiConfig
