import reduxHelper from '../utils/redux'

export const reduxUtil = reduxHelper('AREA')

const { defineAction, createAction } = reduxUtil

export const actionTypes = {
    GET_AREA: defineAction('GET_AREA'),
    GET_AREA_FIRST: defineAction('GET_AREA_FIRST'),
    GET_UNIVERSITY: defineAction('GET_UNIVERSITY'),
    GET_JOB: defineAction('GET_JOB'),
}

export const actions = {
    getArea: createAction(actionTypes.GET_AREA),
    getAreaFirst: createAction(actionTypes.GET_AREA_FIRST),
    getUniversity: createAction(actionTypes.GET_UNIVERSITY),
    getJob: createAction(actionTypes.GET_JOB),
}
