import React from 'react'
import { Form, Input, Card, Row, Col, Image } from "antd"
import { AppConstants } from '../../../constants';
const { TextArea } = Input;

const FillAnswer = ({ handleSubmit, formName, questionData, buttonSubmit }) => {
    return (
        <Form
            name={formName}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{}}
            onFinish={handleSubmit}
        >
            <Card size="small">
                <Row gutter={16}>
                    <Col span={24} md={{ order: 2, span: 8 }}>
                        <div className="py-4 px-6">
                            <Image width="100%" src={AppConstants.contentRootUrl + questionData.questionAvatar} alt="image-question" />
                        </div>
                    </Col>
                    <Col span={24} md={{ order: 1, span: 16 }}>
                        <Form.Item
                            name="answer"
                            wrapperCol={{
                                span: 24
                            }}
                            className="mb-0 py-4"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng điền đáp án!',
                                }
                            ]}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                {buttonSubmit()}
            </Card>
        </Form>
    )
};

export default FillAnswer;
