import React, { useMemo } from 'react'
// import logo from '../../assets/images/badge.png';
import { RatingScale } from '../../constants/masterData';
import Utils from '../../utils';
import { Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import BaseTable from '../common/table/BaseTable';
import { convertStringToDateTimeString } from '../../utils/datetimeHelper';
import { isOnLevel
 } from '../../constants/masterData';
function PerformedTestDetail({
    dataDetail,
    listLoading,
    pagination,
    handleTableChange,
    handleBack
}) {

    const columns = useMemo(() => {
        return [
            {
              title: 'Năng lực',
              dataIndex: 'competenceName',
              render: (competenceName) => (<div className="competence-name">
                {/* <img className="imagePath imagePath--detail"
                    src={logo}
                /> */}
                <div>{competenceName}</div></div>
              ),
            },
            {
                title: 'Bắt đầu',
                align: 'center',
                width: 100,
                render: (dataRow) => (
                    <div className="score">
                        {
                            dataRow.maxScore > 0 && isOnLevel('initial', dataRow.score) ? <CheckOutlined style={{color: "#1890ff"}} /> : "-"
                        }
                    </div>
                )
            },
            {
                title: 'Cơ bản',
                align: 'center',
                dataIndex: 'score',
                width: 100,
                render: (score) => (
                    <div className="score">
                        {
                            isOnLevel('basic', score) ? <CheckOutlined style={{color: "#1890ff"}} /> : "-"
                        }
                    </div>
                )
            },
            {
                title: 'Trung bình',
                align: 'center',
                dataIndex: 'score',
                width: 110,
                render: (score) => (
                    <div className="score">
                        {
                            isOnLevel('medium', score) ? <CheckOutlined style={{color: "#1890ff"}}/> : "-"
                        }
                    </div>
                )
            },
            {
                title: 'Nâng cao',
                align: 'center',
                dataIndex: 'score',
                width: 100,
                render: (score) => (
                    <div className="score">
                        {
                            isOnLevel('advanced', score) ? <CheckOutlined style={{color: "#1890ff"}}/> : "-"
                        }
                    </div>
                )
            },
            {
                title: <div style={{textAlign: 'center'}}>Mức độ đạt được</div>,
                align: 'right',
                width: 100,
                render: (dataRow) => {
                    const result = new Intl.NumberFormat('vi-VI', { maximumSignificantDigits: 3 }).format(dataRow.score / dataRow.maxScore * 100);
                    return (
                        <div className="max-score">
                            {
                                dataRow.maxScore > 0 ? result + '%' : '0%'
                            }
                        </div>
                    )
                }
            },
        ];
    }, [dataDetail])

    return (
        <div>
                {
                    dataDetail.surveyName ?  (<>
                        <div className="title">{`${dataDetail.surveyName}: ${dataDetail.workFieldName}`}</div>
                        <div className="info-performed-test">
                            <div className="create-date"><strong>Ngày bắt đầu: </strong>{convertStringToDateTimeString(dataDetail.createDate, 'DD/MM/YYYY hh:mm:ss', 'DD/MM/YYYY (hh:mm)')}</div>
                            <div className="status">
                                {
                                    !Number(dataDetail.isFinish)
                                    ? <div><strong>Trạng thái: </strong>Chưa hoàn thành</div>
                                    : <div><strong>Trạng thái: </strong>Đã hoàn thành</div>
                                }
                            </div>
                        </div> </>
                    ) : null
                }
                
            <BaseTable 
                className="base-table"
                size="default"
                scroll={{ x: 800 }}
                loading={listLoading}
                columns={columns}
                rowKey={(record) => record.competenceName}
                dataSource={dataDetail?.workFieldName && dataDetail?.data  || []}
                size="default"
                pagination={{
                    ...pagination,
                    total: dataDetail?.totalElements || 10
                }}
                onChange={(pagination) => {handleTableChange (pagination)}}
            />
            <div className="btn-back">
                <Button type="primary" onClick={() => { handleBack() }}>Trở về</Button>
            </div>
        </div>
    )
}

export default PerformedTestDetail
