import React from 'react';
import { commonQuestionKind } from "../../constants/masterData";

const BaseQuestion = ({ questionData, ...rest }) => {
    const renderQuestion = () => {
        const questionKind = commonQuestionKind.find(quest => quest.value === questionData.questionKind)
        const props = {
            ...rest,
            questionData
        }

        const QComponent = questionKind.Component

        return <QComponent {...props} key={questionData.id} />
    }
    return renderQuestion();
};

export default BaseQuestion;
