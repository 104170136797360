import { actionTypes, reduxUtil } from '../actions/module'

const initialState = {
    moduleCombobox: [],
}

const reducer = reduxUtil.createReducer(
    {
        [reduxUtil.defineActionSuccess(actionTypes.GET_MODULE_COMBOBOX)]: (
            state,
            { data }
        ) => {
            return {
                ...state,
                moduleCombobox: data,
            }
        },
    },
    initialState
)

export default {
    reducer,
}
