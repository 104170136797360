import reduxHelper from '../utils/redux'

export const reduxUtil = reduxHelper('COMPETENCES')

const { defineAction, createAction } = reduxUtil

export const actionTypes = {
    GET_PERFORMED_TEST: defineAction('GET_PERFORMED_TEST'),
    GET_PERFORMED_TEST_DETAIL: defineAction('GET_PERFORMED_TEST_DETAIL'),
    GET_SURVEY_PROFILE_LIST: defineAction('GET_SURVEY_PROFILE_LIST'),
    GET_COMPETENCE_PROFILE_LIST: defineAction('GET_COMPETENCE_PROFILE_LIST'),
    GET_DIGITAL_PROFILE_LIST: defineAction('GET_DIGITAL_PROFILE_LIST'),
}

export const actions = {
    getPerformedTest: createAction(actionTypes.GET_PERFORMED_TEST),
    getPerformedTestDetail: createAction(actionTypes.GET_PERFORMED_TEST_DETAIL),
    getSurveyProfileList: createAction(actionTypes.GET_SURVEY_PROFILE_LIST),
    getCompetenceProfileList: createAction(actionTypes.GET_COMPETENCE_PROFILE_LIST),
    getDigitalProfileList: createAction(actionTypes.GET_DIGITAL_PROFILE_LIST),
}
