import React, {useState} from 'react'
import { Row, Col, Button, Popover } from 'antd'
import CardFeat from '../common/elements/CardFeat'
import ModalVideo from 'react-modal-video'

import video from '../../assets/images/video.png'
import heartbeat from '../../assets/images/heartbeat.png'
import desktop_content from '../../assets/images/desktop_content.png'
import battery2 from '../../assets/images/battery2.png'



const ExamsPage = ({renderRouting}) => {
    const [isOpen, setOpen] = useState(false)

    const list = [
        {
            title: 'Trước khi làm bài',
            content: (
                <>
                    Bạn nên xem video
                    <div>
                        <button
                            style={{border:0, color:"#2e9dea", cursor: "pointer"}}
                        >
                            Hướng dẫn làm bài
                        </button>
                    </div>
                    trước khi bắt đầu.
                </>
            ),
            img: video,
            onClick: (value)=>setOpen(value),
        },
        {
            title: 'Làm bài',
            content: (
                <>
                    Bài đánh giá năng lực số gồm <strong>5 phần</strong>.
                    Bạn cần hoàn thành các câu hỏi của từng phần trước khi chuyển sang phần tiếp theo.
                </>
            ),
            img: heartbeat,
        },
        {
            title: 'Thời gian',
            content: (
                <>
                    Bạn sẽ tốn khoảng <strong>30 phút</strong> để làm bài.
                    Nếu chưa thể hoàn thành cả 5 phần cùng lúc,
                    bạn có thể quay lại tiếp tục sau đó.
                </>
            ),
            img: battery2,
        },
        {
            title: 'Kết thúc',
            content:
                'Sau khi hoàn thành bài đánh giá năng lực số, bạn có thể tra cứu hồ sơ năng lực số và tải về chứng chỉ năng lực số.',
            img: desktop_content,
        },
    ]
    return (
        <div className="exam-page py-8">
            <Row gutter={[24, 24]}>
                {list.map((el, index) => (
                    <Col xs={24} xl={6} style={ el.onClick ? { cursor:'pointer' } : {} }>
                        <CardFeat key={index} {...el} />
                    </Col>
                ))}
            </Row>
            <ModalVideo allowFullScreen={true} channel='youtube' autoplay isOpen={isOpen} videoId="Y4WcNIv71UU" ratio='16:9'
                onClose={() => setOpen(false)}
            />
            <div className="mt-8 text-center">
                <Button size="large" type="primary" onClick={renderRouting}>
                    Bắt đầu >
                </Button>
            </div>
        </div>
    )
}

export default ExamsPage
