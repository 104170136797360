import ChooseAnswer from '../components/test/kind/ChooseAnswer'
import React, { Component } from 'react';

import { ANSWER_STATUS } from './index';
import { CheckOutlined } from '@ant-design/icons';
import FillAnswer from '../components/test/kind/FillAnswer';
import AttachFillAnswer from '../components/test/kind/AttachFillAnswer';
import AttachChooseAnswer from '../components/test/kind/AttachChooseAnswer';
import SetOfQuestions from '../components/test/kind/SetOfQuestions';
import BrowserFillAnswer from '../components/test/kind/BrowserFillAnswer';
import BrowserChooseAnswer from '../components/test/kind/BrowserChooseAnswer';
import StepByStepOnImages from '../components/test/kind/StepByStepOnImages';
import EmailQuestion from '../components/test/kind/EmailQuestion';

import { QUESTION_TYPE } from '.';

const GENDER_MALE = 1
const GENDER_FEMALE = 2
const GENDER_OTHER = 3

export const genders = [
    { value: GENDER_MALE, label: 'Nam' },
    { value: GENDER_FEMALE, label: 'Nữ' },
    { value: GENDER_OTHER, label: 'Khác' },
]

export const commonQuestionKind = [
    {
        value: QUESTION_TYPE.TYPE1,
        label: 'Chọn đáp án đúng',
        isAttachmentRequired: false,
        Component: ChooseAnswer,
    },
    {
        value: QUESTION_TYPE.TYPE2,
        label: 'Điền câu trả lời',
        isAttachmentRequired: false,
        Component: FillAnswer,
    },
    {
        value: QUESTION_TYPE.TYPE3,
        label: 'Đọc tài liệu điền câu trả lời',
        isAttachmentRequired: true,
        Component: AttachFillAnswer,
    },
    {
        value: QUESTION_TYPE.TYPE4,
        label: 'Đọc tài liệu chọn đáp án đúng',
        isAttachmentRequired: true,
        Component: AttachChooseAnswer,
    },
    {
        value: QUESTION_TYPE.TYPE5,
        label: 'Tập hợp câu hỏi',
        isAttachmentRequired: false,
        Component: SetOfQuestions,
    },
    {
        value: QUESTION_TYPE.TYPE6,
        label: 'Duyệt web trả lời câu hỏi',
        isAttachmentRequired: false,
        Component: BrowserFillAnswer,
    },
    {
        value: QUESTION_TYPE.TYPE7,
        label: 'Duyệt web chọn đáp án đúng',
        isAttachmentRequired: false,
        Component: BrowserChooseAnswer,
    },
    {
        value: QUESTION_TYPE.TYPE8,
        label: 'Xem hình ảnh chọn vị trí đúng',
        isAttachmentRequired: false,
        Component: StepByStepOnImages,
    },
    {
        value: QUESTION_TYPE.TYPE9,
        label: 'Đọc và điền thông tin về email tương ứng',
        isAttachmentRequired: false,
        Component: EmailQuestion,
    },
]

export const MOCK_QUEST = [
    {},
    {
        "id": 1070,
        "status": 1,
        "modifiedDate": "06/06/2021 04:57:09",
        "createdDate": "05/06/2021 15:17:26",
        "modifiedBy": "admin",
        "createdBy": "admin",
        "questionName": "Chương trình giúp người dùng truy cập internet là gì?",
        "questionContent": "[{\"content\":\"Web search engine\",\"rightAnswer\":false},{\"content\":\"Search engine\",\"rightAnswer\":false},{\"content\":\"Web optimizer\",\"rightAnswer\":false},{\"content\":\"Web browser\",\"rightAnswer\":true}]",
        "questionAvatar": "/AVATAR/AVATAR_UiJNtEv65F.",
        "questionAttachment": "/AVATAR/AVATAR_OCpBL9kAE8.jpg",
        "questionScore": 20,
        "questionKind": 1,
        "questionRank": 1,
        "questionCompetencesId": 1054
    },
    {
        "id": 1082,
        "status": 1,
        "modifiedDate": "06/06/2021 07:17:18",
        "createdDate": "06/06/2021 07:17:18",
        "modifiedBy": "admin",
        "createdBy": "admin",
        "questionName": "Nhập câu trả lời đúng",
        "questionContent": "\"Câu trả lời\"",
        "questionAvatar": "/AVATAR/AVATAR_AmWeNunI4g.",
        "questionScore": 20,
        "questionKind": 2,
        "questionRank": 3,
        "questionCompetencesId": 1054
    },
    {
        "id": 1083,
        "status": 1,
        "modifiedDate": "06/06/2021 07:18:37",
        "createdDate": "06/06/2021 07:18:37",
        "modifiedBy": "admin",
        "createdBy": "admin",
        "questionName": "Đọc tài liệu trả lời câu hỏi",
        "questionContent": "\"Câu trả lời\"",
        "questionAvatar": "/AVATAR/AVATAR_Mfgd4VfvK9.",
        "questionAttachment": "/AVATAR/AVATAR_lnKJsQujVv.docx",
        "questionScore": 20,
        "questionKind": 3,
        "questionRank": 1,
        "questionCompetencesId": 1064
    },
    {
        "id": 1162,
        "status": 1,
        "modifiedDate": "21/06/2021 16:36:53",
        "createdDate": "21/06/2021 16:36:53",
        "modifiedBy": "admin",
        "createdBy": "admin",
        "questionName": "Test",
        "questionContent": "[{\"content\":\"Test1\",\"rightAnswer\":true},{\"content\":\"Test2\",\"rightAnswer\":false},{\"content\":\"Test3\",\"rightAnswer\":false}]",
        "questionAvatar": "/AVATAR/AVATAR_Sg73JX0i1d.",
        "questionAttachment": "/AVATAR/AVATAR_N1L5wUCCKC.jpg",
        "questionScore": 20,
        "questionKind": 4,
        "questionRank": 1,
        "questionCompetencesId": 1146
    },
    {
        "id": 1118,
        "status": 1,
        "modifiedDate": "09/06/2021 08:14:34",
        "createdDate": "08/06/2021 18:11:48",
        "modifiedBy": "admin",
        "createdBy": "admin",
        "questionName": "Chia sẻ thông qua các công nghệ kỹ thuật số",
        "questionContent": "{\"questions\":[{\"question\":\"Bạn có thái độ chủ động trong việc chia sẻ tài nguyên, nội dung và kiến thức \",\"rightAnswer\":[false,false,false,true,false]},{\"question\":\"Bạn có ý kiến sáng suốt của riêng mình về các phương pháp chia sẻ, lợi ích, rủi ro và giới hạn\",\"rightAnswer\":[false,false,true,false,false]},{\"question\":\"Bạn có một ý kiến sáng suốt về các phương pháp tác giả\",\"rightAnswer\":[false,true,false,false,false]},{\"question\":\"Bạn biết về vấn đề bản quyền\",\"rightAnswer\":[true,false,false,false,false]}],\"answerList\":[\"Hoàn toàn đồng ý\",\"Đồng ý\",\"Phân vân\",\"Không đồng ý\",\"Hoàn toàn không đồng ý\"]}",
        "questionAvatar": "/AVATAR/AVATAR_PLMLa6NT0Q.",
        "questionScore": 20,
        "questionKind": 5,
        "questionRank": 4,
        "questionCompetencesId": 1054
    },
    {
        "id": 1085,
        "status": 1,
        "modifiedDate": "06/06/2021 09:06:55",
        "createdDate": "06/06/2021 07:31:39",
        "modifiedBy": "admin",
        "createdBy": "admin",
        "questionName": "Duyệt web nhập câu trả lời",
        "questionContent": "[{\"url\":\"http://localhost:3000/question\",\"answer\":\"Cau tl\"}]",
        "questionAvatar": "/AVATAR/AVATAR_nIyLpyqnmZ.",
        "questionScore": 20,
        "questionKind": 6,
        "questionRank": 1,
        "questionCompetencesId": 1057
    },
    {
        "id": 1087,
        "status": -1,
        "modifiedDate": "06/06/2021 09:06:11",
        "createdDate": "06/06/2021 08:28:48",
        "modifiedBy": "admin",
        "createdBy": "admin",
        "questionName": "Duyệt web chọn câu trả lời",
        "questionContent": "[{\"url\":\"http://localhost:3000/questionk\",\"question\":[{\"content\":\"Câu trả lời 1\",\"rightAnswer\":false},{\"content\":\"Câu trả lời 2\",\"rightAnswer\":true},{\"content\":\"Câu trả lời 3\",\"rightAnswer\":false},{\"content\":\"Câu trả lời 4\",\"rightAnswer\":false},{\"content\":\"Câu trả lời 5\",\"rightAnswer\":false}]}]",
        "questionAvatar": "/AVATAR/AVATAR_37lhZsH6pz.",
        "questionScore": 20,
        "questionKind": 7,
        "questionRank": 1,
        "questionCompetencesId": 1057
    },
]
export const RatingScale = {
    initial: {
        name: 'Bắt đầu',
        min: 0,
        max: 0,
    },
    basic: {
        name: 'Cơ bản',
        min: 1,
        max: 3,
    },
    medium: {
        name: 'Trung bình',
        min: 4,
        max: 6,
    },
    advanced: {
        name: 'Nâng cao',
        min: 7,
        max: 10,
    }
}
export const levels = [
    {
        level: 1,
        label: 'Cơ bản'
    },
    {
        level: 2,
        label: 'Trung bình'
    },
    {
        level: 3,
        label: 'Nâng cao'
    },
]

export const mapLevelToDescription = {
    1: 'competencesDescriptionBasic',
    2: 'competencesDescriptionMedium',
    3: 'competencesDescriptionAdvanced'
}

export const mapLevelToAreaDescription = {
    1: "areaDescriptionBasic",
    2: "areaDescriptionMedium",
    3: "areaDescriptionAdvanced",
}

export const QuestionRank = [
    {
        value: 2,
        label: 'Cơ bản',
        score: 1,
    },
    {
        value: 3,
        label: 'Trung bình',
        score: 2,
    },
    {
        value: 4,
        label: 'Nâng cao',
        score: 3,
    },
    {
        value: 5,
        label: 'Kỹ năng',
        score: 4,
    },
]

export const isOnLevel = (levelKey, point) => {
    return RatingScale[levelKey].min <= point && point <= RatingScale[levelKey].max;
}

export  const calcLevelFromPoint = point => {
    if (point >= RatingScale["advanced"].min) {
        return levels[2].level
    }

    if (point >= RatingScale["medium"].min) {
        return levels[1].level
    }

    if (point >= RatingScale["basic"].min) {
        return levels[0].level
    }

    return 0
}

export const getQuestionPoint = question => {
    return question.isRight === ANSWER_STATUS.TRUE_ANSWER ? question.questionScore : 0
}

export const getCompetencesColumns = (index) => ([
    {
        dataIndex: 'competenceName',
        className: 'competence',
        render: (competenceName, dataRow, i) => {
            return (
                <div key={dataRow.competenceId} >
                    {(index + 1) + "." + (i + 1) } {competenceName}
                </div>
            )
        }
    },
    {
        align: 'center',
        dataIndex: 'score',
        width: 100,
        className:"score",
        render: (score, dataRow, i) => {
            return (
                <div key={dataRow.competenceId}>
                    {
                        isOnLevel('basic', score) ? <CheckOutlined style={{color: "#1890ff"}} /> : "-"
                    }
                </div>
            )
        }
    },
    {
        align: 'center',
        dataIndex: 'score',
        width: 110,
        className:"score",
        render: (score, dataRow, i) => {
            return (
                <div key={dataRow.competenceId}>
                    {
                        isOnLevel('medium', score) ? <CheckOutlined style={{color: "#1890ff"}} /> : "-"
                    }
                </div>
            )
        }
    },
    {
        align: 'center',
        dataIndex: 'score',
        width: 100,
        className:"score",
        render: (score, dataRow, i) => {
            return (
                <div key={dataRow.competenceId}>
                    {
                        isOnLevel('advanced', score) ? <CheckOutlined style={{color: "#1890ff"}} /> : "-"
                    }
                </div>
            )
        }
    },
])