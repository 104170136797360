import { call, takeLatest, put, putResolve,takeEvery } from 'redux-saga/effects'

import { sendRequest } from '../services/apiService'
import { actionTypes, reduxUtil } from '../actions/test'
import apiConfig from '../constants/apiConfig'
import { ensureArray } from '../utils/helper'

import { actions as appActions } from '../actions/appCommon'

const { GET_LIST_COMPETENCE_BY_MODULE_COMBOBOX,  GET_LIST_QUESTION_BY_COMPETENCE_COMBOBOX, EXAM_ANSWER, FINISH_EXAM} = actionTypes
const DEFAULT_TABLE_ITEM_SIZE = 2;
const { defineActionSuccess, defineActionLoading } = reduxUtil

// function* getListCompetenceByModuleCombobox({ payload: { onCompleted, onError, params } }) {
//     try {
//         const searchParams = {};
//         if(params){
//             if(params.moduleId ) searchParams.moduleId  = params.moduleId ;
//         }
//         const { success, responseData } = yield call(
//             sendRequest,
//             apiConfig.test.listCompetenceByModuleCombobox,
//         )
//         if (success && responseData.result) {
//             onCompleted && onCompleted(responseData.data?.data)
//             yield put({
//                 type: defineActionSuccess(GET_LIST_COMPETENCE_BY_MODULE_COMBOBOX),
//                 data: ensureArray(responseData.data?.data),
//             })
//         } else {
//             onError && onError(responseData)
//         }
//     } catch (error) {
//         onError && onError(error)
//     }
// }

// function* getListCompetenceByModuleCombobox({ payload: { params, onCompleted, onError } }) 
// {
//         try {
//             const searchParams = {};
//             if(params){
//                 if(params.moduleId ) searchParams.moduleId  = params.moduleId ;
//             }
//             const { success, responseData } = yield call(
//                 sendRequest,
//                 apiConfig.test.listCompetenceByModuleCombobox,
//                 searchParams,

//             )
//             if (success && responseData.result) {
//                 onCompleted && onCompleted(responseData.data?.data)
//                 yield put({
//                     type: defineActionSuccess(GET_LIST_COMPETENCE_BY_MODULE_COMBOBOX),
//                     data: ensureArray(responseData.data?.data),
//                     examId: responseData.data.examId,

//                 })
//             } else {
//                 onError && onError(responseData)
//             }
//         } catch (error) {
//             onError && onError(error)
//         }
// }

function* getListCompetenceByModuleCombobox({ payload: { params, onCompleted, onError } }) 
{
        try {
            const searchParams = {};
            if(params){
                if(params.moduleId ) searchParams.moduleId  = params.moduleId ;
            }
            const { success, responseData } = yield call(
                sendRequest,
                apiConfig.test.listCompetenceByModuleCombobox,
                searchParams,

            )
            if (success && responseData.result) {
                onCompleted && onCompleted(responseData.data?[
                    responseData.data.data,
                    responseData.data.examId,
                    responseData.data.area,
                    responseData.data.areaDescriptionBasic,
                    responseData.data.areaDescriptionMedium,
                    responseData.data.areaDescriptionAdvanced,
                ]:[])
                yield put({
                    // yield putResolve({   
                    type: defineActionSuccess(GET_LIST_COMPETENCE_BY_MODULE_COMBOBOX),
                    data: ensureArray(responseData.data?.data),
                    // examId: responseData.data.examId,
                })
            } else {
                onError && onError(responseData)
            }
        } catch (error) {
            onError && onError(error)
        }
}

function* getListQuestionByCompetenceCombobox({ payload: { params, onCompleted, onError } }) 
{
    try {
        const searchParams = {};
        if(params){
            if(params.competenceId) 
                searchParams.competenceId  = params.competenceId ;
            if(params.examId) 
                searchParams.examId  = params.examId  ;
                // searchParams  = {competenceId:params.competenceId} ;
        }
        const { success, responseData } = yield call(
            sendRequest,
            apiConfig.test.listQuestionByCompetenceCombobox,
            searchParams,

        )
        if (success && responseData.result) {
            onCompleted && onCompleted(responseData.data?.data)
            // yield put({
                yield putResolve({  
                type: defineActionSuccess(GET_LIST_QUESTION_BY_COMPETENCE_COMBOBOX),
                data: ensureArray(responseData.data?.data),
            })
        } else {
            onError && onError(responseData)
        }
    } catch (error) {
        onError && onError(error)
    }
}

function* examAnswer({ payload: { params, onError, onCompleted } }) {
    try {
        const { success, responseData } = yield call(
            sendRequest,
            apiConfig.test.examAnswer,
            params
        )
        if (success && responseData.result) {
            onCompleted(responseData)
        } else {
            onError(responseData)
        }
    } catch (error) {
        onError(error)
    }
}

function* _finishExam({ payload: { params, onError, onCompleted } }) {
    yield put(appActions.showFullScreenLoading())
    try {
        const { success, responseData } = yield call(
            sendRequest,
            {
                ...apiConfig.test.finishExam,
                path: apiConfig.test.finishExam.path + '/' + params.id
            },
            {}
        )
        if (success && responseData.result) {
            onCompleted(responseData)
        } else {
            onError(responseData)
        }
    } catch (error) {
        onError(error)
    } finally {
        yield put(appActions.hideFullScreenLoading())
    }
}

const sagas = [
    takeLatest(GET_LIST_COMPETENCE_BY_MODULE_COMBOBOX, getListCompetenceByModuleCombobox),
    // takeEvery(GET_LIST_QUESTION_BY_COMPETENCE_COMBOBOX, getListQuestionByCompetenceCombobox),
    takeLatest(EXAM_ANSWER, examAnswer),
    takeEvery(defineActionLoading(GET_LIST_QUESTION_BY_COMPETENCE_COMBOBOX), getListQuestionByCompetenceCombobox),
    takeLatest(FINISH_EXAM, _finishExam),

    // takeLatest(GET_CATEGORY_COMBOBOX, getCategoryCombobox),

]

export default sagas
