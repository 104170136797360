import React, { useState, useEffect, useRef } from 'react';
import { Spin, Form, Card, Row, Modal } from 'antd';
import Utils from "../../../utils";
import { convertDateToString } from "../../../utils/datetimeHelper";
import { showErrorMessage } from "../../../services/notifyService";
import { AppConstants } from '../../../constants';

const { warning } = Modal;
const LIMIT_TIMES_ON_CLICK = 10;

function StepByStepOnImages({ handleSubmit, formName, questionData, buttonSubmit }) {
    const [currentImage, setCurrentImage] = useState(questionData.questionContent[0]);
    const [historyClick, setHistoryClick] = useState([{
        id: questionData.questionContent[0].id,
        regions: [],
    }]);
    const [isWarned, setIsWarned] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const imageRef = useRef(undefined);

    const handleClickImage = (e) => {
        e = e || window.event;

        const target = e.target || e.srcElement || imageRef.current,
            rect = target.getBoundingClientRect(),
            offsetX = e.clientX - rect.left,
            offsetY = e.clientY - rect.top;

        const x = currentImage.region.x;
        const y = currentImage.region.y;
        const w = currentImage.region.width;
        const h = currentImage.region.height;
        const imageW = imageRef.current.width;
        const imageH = imageRef.current.height;

        const correctRegion = {
            x1: x*imageW/100,
            y1: y*imageH/100,
            x2: x*imageW/100+w*imageW/100,
            y2: y*imageH/100+h*imageH/100,
        }

        let result = false;
        if(
            offsetX >= correctRegion.x1 &&
            offsetX <= correctRegion.x2 &&
            offsetY >= correctRegion.y1 &&
            offsetY <= correctRegion.y2
        ) {
                result = true;
        }

        const newHistoryClick = JSON.parse(JSON.stringify(historyClick));
        const index = newHistoryClick.findIndex(hstImage=>currentImage.id===hstImage.id);
        if(index > -1) {
            newHistoryClick[index] = {
                id: currentImage.id,
                regions: [
                    ...newHistoryClick[index].regions,
                    {
                        x: (offsetX/imageRef.current.width)*100,
                        y: (offsetY/imageRef.current.height)*100,
                        result,
                        time: convertDateToString(new Date(), 'DD/MM/YYYY HH:mm:ss'),
                    }
                ]
            }
        }
        else {
            newHistoryClick.push({
                id: currentImage.id,
                regions: [
                    {
                        x: (offsetX/imageRef.current.width)*100,
                        y: (offsetY/imageRef.current.height)*100,
                        result,
                        time: convertDateToString(new Date(), 'DD/MM/YYYY HH:mm:ss'),
                    }
                ]
            })
        }
        setHistoryClick(newHistoryClick);
        if(!isWarned && !result && newHistoryClick[index > -1 ? index : newHistoryClick.length - 1].regions.length > LIMIT_TIMES_ON_CLICK){
            Modal.warning({
                title: 'Bạn đã click quá giới hạn quy định!',
                content: 'Nếu không có câu trả lời, vui lòng nhấn nút "Phản hồi".',
                onOk: () => {
                    setIsWarned(true);
                }
            });
        }
        if(result) {
            let i = questionData.questionContent.findIndex(image=>image.id===currentImage.id);
            if(i + 1=== questionData.questionContent.length) onFinish(newHistoryClick);
            else {
                Promise.resolve().then(() => {
                    setImageLoading(true);
                    setCurrentImage(questionData.questionContent[i + 1]);
                });
            }
        }
    }

    const onFinish = (historyClick) => {
        handleSubmit(historyClick);
    }
    return (
        <Form
            name={formName}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{}}
            onFinish={onFinish}
            className="step-by-step-images"
        >
            <Card size="small">
                <Spin spinning={imageLoading}>
                    <Row gutter={16}>
                        <div style={{
                            width: '100%',
                            height: '100%',
                            overflow: 'auto',
                            backgroundColor: '#000',
                        }}>
                            <img 
                            onLoad={() => { setImageLoading(false) }}
                            onClick={handleClickImage} ref={imageRef} src={`${AppConstants.contentRootUrl}${currentImage?.path}`} style={{
                                margin: '0 auto',
                                display: 'block',
                                maxWidth: '100%',
                                maxHeight: '100%',
                                width: 'auto',
                                height: 'auto',
                            }}/>
                        </div>
                    </Row>
                </Spin>
                {buttonSubmit()}
            </Card>
        </Form>
    );
}

export default StepByStepOnImages;