import reduxHelper from '../utils/redux'

export const reduxUtil = reduxHelper('TEST')

const { defineAction, createAction,createActionWithLoading } = reduxUtil

export const actionTypes = {
    GET_LIST_COMPETENCE_BY_MODULE_COMBOBOX: defineAction('GET_LIST_COMPETENCE_BY_MODULE_COMBOBOX'),
    GET_LIST_QUESTION_BY_COMPETENCE_COMBOBOX: defineAction('GET_LIST_QUESTION_BY_COMPETENCE_COMBOBOX'),
    EXAM_ANSWER: defineAction('EXAM_ANSWER'),
    FINISH_EXAM: defineAction('FINISH_EXAM'),
}

export const actions = {
    getListCompetenceByModuleCombobox: createAction(actionTypes.GET_LIST_COMPETENCE_BY_MODULE_COMBOBOX),
    // getListQuestionByCompetenceCombobox: createAction(actionTypes.GET_LIST_QUESTION_BY_COMPETENCE_COMBOBOX),
    getListQuestionByCompetenceCombobox: createActionWithLoading(actionTypes.GET_LIST_QUESTION_BY_COMPETENCE_COMBOBOX),

    examAnswer: createAction(actionTypes.EXAM_ANSWER),
    finishExam: createAction(actionTypes.FINISH_EXAM),
}
