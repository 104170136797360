import {
    actions as appCommonActions,
    actionTypes as appCommonTypes,
} from './appCommon'
import {
    actions as accountActions,
    actionTypes as accountTypes,
} from './account'

import {
    actions as aboutUsActions,
    actionTypes as aboutUsTypes,
} from './aboutUs'

import {
    actions as moduleActions,
    actionTypes as moduleTypes,
} from './module'
import {
    actions as testActions,
    actionTypes as testTypes,
} from './test'
import {
    actions as competencesActions,
    actionTypes as competencesTypes,
} from './competences'

export const actions = {
    ...appCommonActions,
    ...accountActions,
    ...aboutUsActions,
    ...moduleActions,
    ...testActions,
    ...competencesActions
}

export const types = {
    ...appCommonTypes,
    ...accountTypes,
    ...aboutUsTypes,
    ...moduleTypes,
    ...testTypes,
    ...competencesTypes
}
