import React, { useRef } from 'react';
import { Radio, Card, Form, Row, Col } from 'antd'
import BaseTable from '../../../components/common/table/BaseTable'

const SetOfQuestions = ({ questionData, handleSubmit, formName, buttonSubmit }) => {
    const [ form ] = Form.useForm();
    const { questions, answerList } = questionData.questionContent;
    const refSubmitted = useRef(false)

    const handleCheckAnswer = (indexRow, indexCol) => {
        const answer = form.getFieldValue('answer') || []
        answer[indexRow] = indexCol
        form.setFieldsValue({ answer });
        refSubmitted.current && form.validateFields(['answer'])
    }

    const columns =  [
        {
            title: '',
            dataIndex: 'question',
            key: 'question',
        },
        ...answerList.map((el, index) => ({
            title: el,
            key: index,
            align: "center",
            render: (dataCol, data, rowIndex) => (
                <Form.Item
                    name={[ 'answer', rowIndex ]}
                    className="mb-0"
                >
                    <Radio
                        value={index}
                        onChange={_ => handleCheckAnswer(rowIndex, index)}
                        checked={form.getFieldValue('answer')[rowIndex] === index}
                    />
                </Form.Item>
            )
        }))
    ]

    return (
       <Form
            form={form}
            name={formName}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{}}
            onFinish={values => {
                refSubmitted.current = true
                handleSubmit(values)
            }}
        >
            <Card size="small"  style={{ width: '100%', marginBottom: '4px' }}>
                <div>
                </div>
                <Form.Item
                    rules={[
                        {
                            validator: data => {
                                const answer = form.getFieldValue('answer')
                                if (answer.length !== questions.length || answer.some(a => a !== 0 && !a)) {
                                    return Promise.reject('Vui lòng hoàn thành hết các câu hỏi!');
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }
                    ]}
                    name="answer"
                    wrapperCol={{ span: 24 }}
                    initialValue={questions.map(_ => '')}
                >
                    <BaseTable
                        columns={columns}
                        dataSource={questions.map((q, index) => ({ ...q, key: index }))}
                        pagination={false}
                    />
                </Form.Item>
                {buttonSubmit()}
            </Card>
       </Form>
    )
};

export default SetOfQuestions;