import { Button, Card, Col, Layout, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { actions } from '../../actions';
import sitePathConfig from '../../constants/sitePathConfig';
import {
    moduleComboboxSelector
} from '../../selectors/module';
import { showErrorMessage } from '../../services/notifyService';


const PreLoginPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const modulecombobox = useSelector(moduleComboboxSelector);
    const [id, setId] = useState(undefined);


    useEffect(() => {
        dispatch(
            actions.getListModule({
                onCompleted: data => {
                    if (data?.length > 0) {
                    }
                },
                onError: error => {
                    showErrorMessage(
                        error?.message ||
                            'Đã xảy ra lỗi trong quá trình lấy dữ liệu!'
                    )
                },
            })
        )
    }, [])
    return (
        <Layout className="preLogin-page" >
        <div>
            <div className="alert-box">
                <Card>
                    <p>Vui lòng chọn thành tố năng lực số và nhấn vào nút "Bắt đầu kiểm tra" để thực hiện bài đánh giá. Cám ơn bạn đã tham gia cùng chúng tôi, chúc bạn nhiều may mắn!</p>
                </Card>
            </div>
            <div className="panel">
                <Card>
                    <Row>
                        <Col  className="title"><strong>Các thành tố năng lực số:</strong></Col>
                        {/* span={4} */}
                        <Col  className="selectbox">
                        {/* span={20} */}
                            <Select
                                options={modulecombobox?modulecombobox.map(c=>{
                                    // return {
                                    //     value: c.id,
                                    //     label: <div style={{
                                    //         color: 'rgba(0, 0, 0, 0.85)',
                                    //         fontWeight: 'normal',
                                    //     }}>{c.profileName +" - " + c.areaName}</div>,
                                    // }
                                    return {
                                        value: c.id,
                                        label: <div style={{
                                            color: 'rgba(0, 0, 0, 0.85)',
                                            fontWeight: 'normal',
                                        }}>{c.areaName}</div>,
                                    }
                                }):[]}
                                onChange={(value)=>setId(value)}
                                placeholder={<div style={{
                                    color: 'rgba(0, 0, 0, 0.85)',
                                    fontWeight: 'normal',
                                }}>Chọn thành tố năng lực số</div>}
                            >
                            </Select>
                        </Col>
                    </Row>
                    <Button size="large" type="primary" onClick={()=>id && history.push(`${sitePathConfig.test.path}?moduleId=${id}`)}>
                        Bắt đầu kiểm tra
                    </Button>
                </Card>
            </div>
        </div>
        </Layout>
    )
}

export default PreLoginPage
