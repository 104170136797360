import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Collapse, List, Progress } from 'antd';
import React, { useEffect, useState } from 'react';
import { ANSWER_STATUS } from '../../constants';
import { levels } from '../../constants/masterData';
import { mapLevelToDescription, calcLevelFromPoint, getQuestionPoint } from '../../constants/masterData'
const { Panel } = Collapse

const CompetenceList = ({ competencesData, competenceIndex, answerResult }) => {
    const [activeKey, setActiveKey] = useState(0);

    useEffect(() => {
        competenceIndex !== -1 && setActiveKey(competenceIndex)
    }, [competenceIndex])

    if (competenceIndex === -1) {
        return null
    }

    const handleCountRightAnswer = (indexCompetence, questions) => {
        return (answerResult[indexCompetence].filter(quest => quest.isRight === ANSWER_STATUS.TRUE_ANSWER).length / questions.length) * 100;
    }

    const questionIconStatusMap = {
        [ANSWER_STATUS.NOT_ANSWER]: <EditOutlined />,
        [ANSWER_STATUS.TRUE_ANSWER]: <CheckOutlined style={{ color: '#2e9dea' }}/>,
        [ANSWER_STATUS.FALSE_ANSWER]: <CloseOutlined style={{ color: '#e10000' }}/>
    }

    const validCompetenceData = () => {
        if (competencesData.some(competence => !competence)) {
            return []
        }
        const mixComAndResult = competencesData.map((com, i)=>{
            return {
                ...com,
                questions: com.questions.map((question, j)=>{
                    return {
                        ...question,
                        isRight: answerResult[i][j]?.isRight,
                    }
                })
            }
        })
        return mixComAndResult.map((com, i) => {
            const totalPoint = com.questions.reduce((total, quest) => total + getQuestionPoint(quest), 0)
            const level = calcLevelFromPoint(totalPoint)
            return {
                ...com,
                level,
                description: level ? com[mapLevelToDescription[level]].toUpperCase() : 'INITIAL LEVEL'
            }
        })
    }
    return (
        <List
            itemLayout="horizontal"
            dataSource={validCompetenceData()}
            renderItem={(competence, indexCompetence) => (
                <List.Item key={indexCompetence}>
                    <Collapse activeKey={activeKey} onChange={setActiveKey} style={{
                        border: 'none',
                        borderLeft: `${indexCompetence <= competenceIndex ? '5px solid #2E9DEA' : 'none'}`,
                    }}>
                        <Panel
                            header={
                                <>
                                    {competence.competencesName}
                                    <div className="competence">
                                        <div className="competence__level">
                                            <div className="level-box">
                                                {levels.map(level => (
                                                    <div key={level.level} className={['level-item', competence.level >= level.level && 'active'].filter(Boolean).join(' ')}>
                                                        <div className="level-item__line" />
                                                        {competence.level === level.level && <div className="level-item__label">{level.label.toUpperCase()}</div>}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            key={indexCompetence}
                        >
                            <List
                                itemLayout="horizontal"
                                grid={{ gutter: 12, column: 5 }}
                                dataSource={competence.questions}
                                style={{
                                    backgroundColor: '#CDCFD1',
                                    padding: 16,
                                }}
                                renderItem={(question, indexQuestion) => (
                                    <List.Item className="mb-0">
                                        <div style={{
                                            fontSize: 11,
                                            fontWeight: answerResult[indexCompetence][indexQuestion].isRight !== -1 ? 'bold' : 'normal',
                                        }}>0{indexQuestion + 1}</div>
                                        {questionIconStatusMap[answerResult[indexCompetence][indexQuestion].isRight]}
                                    </List.Item>
                                )}
                            />
                        </Panel>
                    </Collapse>
                </List.Item>
            )}
        />
    );
};

export default CompetenceList;