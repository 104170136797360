import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'
import { Spin } from 'antd'
import { actions } from '../../actions/competences'
import { actions as pageLoadingAction } from '../../actions/appCommon'
import CompetenceProfileContent from '../../components/competences/CompetenceProfileContent'
import DigitalProfileContent from '../../components/competences/DigitalProfileContent'
import { digitalProfileSelector } from '../../selectors/competences'
import { showErrorMessage } from '../../services/notifyService'
import { userDataSelector } from '../../selectors/account'
import jsPDF from 'jspdf'
import * as htmltoimage from 'html-to-image'
import domtoimage from 'dom-to-image'
import logoUTE from '../../assets/images/logo_ute.jpg'
import logoNLU from '../../assets/images/logo_nlu.png'
import logoHUFLIT from '../../assets/images/logo_huflit.png'
import logoKHTN from '../../assets/images/logo_khtn.png'
import logoSPTPHCM from '../../assets/images/logo_sptphcm.jpg'
import logoIU from '../../assets/images/logo_iu.jpg'
import { convertStringToDateTimeString } from '../../utils/datetimeHelper'

const strParams = params => {
    return qs.stringify(params)
}

function DigitalProfile() {
    const dispatch = useDispatch();
    const history = useHistory();
    const pathname = history.location.pathname;
    const { activeKey, page } = qs.parse(history.location.search);
    const digitalProfile = useSelector(digitalProfileSelector);
    const userData = useSelector(userDataSelector);
    const [ listLoading, setListLoading ] = useState(true);

    useEffect(()=>{
        getData();
    }, [page])

    const getData = () => {
        setListLoading(true);
        dispatch(
            actions.getDigitalProfileList({
                onCompleted: responseData => {
                    setListLoading(false)
                },
                onError: error => {
                    setListLoading(false)
                    showErrorMessage(
                        error?.message ||
                            'Đã xảy ra lỗi trong quá trình lấy dữ liệu!'
                    )
                },
            })
        );
    }

    const _noCutRegions = (trArray, offsetTop) => {
        const noCutRegions = [];
        for( let i = 0; i < trArray.length; i++) {
            const hWorkField = document.querySelectorAll(`#cloned-table tbody tr:nth-child(${i + 2}) td.work-field .area`)[0].offsetHeight;
            const offsetTopThisRow = Array.from(trArray).reduce((c, a, index) => index < i ? c + a.offsetHeight : c, offsetTop);
            noCutRegions.push({
                offsetTopThisRow,
                hWorkField: hWorkField,
            })
        }
        return noCutRegions;
    }

    const resizeCanvas = (canvas, w, h) => {
        const cw = canvas.width;
        const ch = canvas.height;
        const tempC = document.createElement('canvas');
        tempC.width=cw;
        tempC.height=ch;
        const tCtx = tempC.getContext('2d');
        tCtx.drawImage(canvas, 0, 0);
        canvas.width = w;
        canvas.height = h;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(tempC, 0, 0, cw, ch, 0, 0, w, h);

        return canvas;
    }

    const getClippedRegion = (image, x, y, width, height) => {

        const canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(image, x, y, width, height,  0, 0, width, height);
        return canvas;
    }

    const handlePrintDocument = (dataList) => {
        if(document.getElementById('myDiv'))document.getElementById('myDiv').parentNode.removeChild(document.getElementById('myDiv'))
        dispatch(pageLoadingAction.showFullScreenLoading())
        const DEBUG = false;
        const A4_PAGE_WIDTH = 1200;
        const PADDING_TABLE = 32;
        const MARGIN = 64;
        const PADDING_ROW = 16;
        const BORDER_BOTTOM_EACH_ROW = 1;
        const myDiv = document.createElement("div");
        myDiv.id = 'myDiv';
        myDiv.style.cssText = `background-color: #fff; padding: ${PADDING_TABLE}px; width: ${A4_PAGE_WIDTH}px;`;
            myDiv.innerHTML = `<div id="header-pdf" class="header-pdf">
                                    <div id="info" class="info-pdf"
                                    >
                                        HỒ SƠ NĂNG LỰC SỐ: ${userData.fullName} - SN: ${convertStringToDateTimeString(userData.birthday, 'DD/MM/YYYY hh:mm:ss', 'DD/MM/YYYY')}
                                    </div>
                                    <div class="header-right">
                                        <div class="col col-1">
                                            <img src="${logoUTE}" />
                                        </div>
                                        <div class="col col-2">
                                            <div>Địa chỉ: 1 Võ Văn Ngân, Phường Linh Chiểu, Quận Thủ Đức, Thành phố Hồ Chí Minh.</div>
                                            <div>Điện thoại: (+84 - 028) 38968641 - (+84 -028) 38961333 - (+84 -028) 37221223</div>
                                        </div>
                                    </div>
                                </div>`;
        const myTable = document.querySelectorAll(".digital-profile-table")[0].cloneNode(true);
        myDiv.append(myTable);
        myTable.style.cssText = "width: 100%; background: '#fff'";
        myTable.id = 'cloned-table';
        myTable.style.cssText = "max-width: 100%";
        //Add footer
        const footer = document.createElement('div');
        footer.innerHTML = `<div id="footer-pdf">
                                <img src="${logoUTE}" />
                                <img src="${logoNLU}" />
                                <img src="${logoIU}" />
                            </div>`;
        myDiv.appendChild(footer);
        document.querySelectorAll(".survey-profile-container")[0].appendChild(myDiv);
        let countLoading = 0;
        try {
            document.querySelectorAll("#myDiv img").forEach(img => {
                img.onload = function() {
                    countLoading++;
                    if(countLoading >= document.querySelectorAll("#myDiv img").length) {
                        htmltoimage.toCanvas(myDiv, { bgcolor: '#fff' })
                        .then(function (canvas) {
                            const ctx = canvas.getContext('2d');
                            canvas = resizeCanvas(canvas, myDiv.offsetWidth, myDiv.offsetHeight)
                            const finalImage = document.createElement("img");
                            const dataUrl = canvas.toDataURL('image/jpeg,1');
                            const pdf = new jsPDF('landscape', 'px', 'a4');
                            const imgProps= pdf.getImageProperties(dataUrl);
                            const pdfWidth = pdf.internal.pageSize.getWidth();
                            const pdfHeight = pdf.internal.pageSize.getHeight();
                            const FOOTER_HEIGHT = document.getElementById('footer-pdf').offsetHeight;
                            const HEIGHT_HEADER = PADDING_TABLE + document.getElementById('header-pdf').offsetHeight + document.querySelectorAll("#cloned-table .ant-table-thead")[0].offsetHeight;
                            const ratePdf = pdfHeight / pdfWidth;
                            finalImage.onload = function(){
                                const finalImageHeightEachPage = finalImage.width * ratePdf;
                                const rateHeight = pdfHeight / finalImageHeightEachPage;
                                if(finalImage.height * rateHeight > pdfHeight - MARGIN * rateHeight) {
                                    // Add all header
                                    let y = 0;
                                    let canvas = getClippedRegion(finalImage, 0, y, finalImage.width, HEIGHT_HEADER);
                                    pdf.addImage(canvas, 'canvas', 0, 0, pdfWidth, HEIGHT_HEADER * rateHeight);
                                    DEBUG && document.body.appendChild(canvas) //For testing
                                    y+=HEIGHT_HEADER;
                                    const trArray = document.querySelectorAll("#cloned-table td.col-competences");
                                    const PADDING_ROW_PDF = PADDING_ROW * rateHeight;
                                    const noCutRegions = _noCutRegions(trArray, HEIGHT_HEADER + PADDING_ROW);
                                    let yPdf = y * rateHeight;
                                    for(let i = 0; i < trArray.length; i++) {
                                        if(
                                            noCutRegions[i].offsetTopThisRow <= Math.floor(y + PADDING_ROW) &&
                                            Math.floor(y + PADDING_ROW) <= Math.floor(noCutRegions[i].offsetTopThisRow + noCutRegions[i].hWorkField) &&
                                            Math.floor(yPdf + PADDING_ROW_PDF + noCutRegions[i].hWorkField * rateHeight) >= Math.floor(pdfHeight - MARGIN * rateHeight) &&
                                            i > 0
                                        ) {
                                            yPdf = MARGIN * rateHeight;
                                            pdf.addPage();
                                        }
                                        canvas = getClippedRegion(finalImage, 0, y, finalImage.width, PADDING_ROW);
                                        DEBUG && document.body.appendChild(canvas) //For testing
                                        pdf.addImage(canvas, 'canvas', 0, yPdf, pdfWidth, PADDING_ROW_PDF);
                                        y+=PADDING_ROW;
                                        yPdf+=PADDING_ROW_PDF;
                                        const tdArray = document.querySelectorAll(`#cloned-table tbody tr:nth-child(${i + 2}) .col-competences .competence`);
                                        let isReachBadge = false;
                                        for(let j = 0; j < tdArray.length; j++) {
                                            const h = tdArray[j].offsetHeight;
                                            const hPdf = h * rateHeight;
                                            const rateNoCutRegionAndH = Math.floor(noCutRegions[i].hWorkField / h) + 1;
                                            if(tdArray.length === 1) // Xét trường hợp chỉ có 1 competence
                                            {
                                                const oneCompetenceRowH = trArray[i].offsetHeight - 2 * PADDING_ROW; // Trừ cho 1 PADDING_ROW đã cộng trước và 1 PADDING_ROW sẽ cộng sau vòng for này
                                                const oneCompetenceRowHPdf = oneCompetenceRowH * rateHeight;
                                                if(
                                                    Math.floor(yPdf + oneCompetenceRowHPdf) >= Math.floor(pdfHeight - MARGIN * rateHeight)
                                                ) {
                                                    yPdf = MARGIN * rateHeight;
                                                    pdf.addPage();
                                                }
                                                canvas = getClippedRegion(finalImage, 0, y, finalImage.width, oneCompetenceRowH);
                                                pdf.addImage(canvas, 'canvas', 0, yPdf, pdfWidth, oneCompetenceRowHPdf);
                                                DEBUG && document.body.appendChild(canvas) //For testing
                                                y += oneCompetenceRowH;
                                                yPdf += oneCompetenceRowHPdf;
                                                continue;
                                            }
                                            if(
                                                j === 0 &&
                                                Math.floor(yPdf + rateNoCutRegionAndH * h) >= Math.floor(pdfHeight - MARGIN * rateHeight)
                                            ) {
                                                yPdf = MARGIN * rateHeight;
                                                pdf.addPage();
                                            }
                                            if(
                                                Math.floor(yPdf + hPdf) >= Math.floor(pdfHeight - MARGIN * rateHeight)
                                            ) {
                                                yPdf = MARGIN * rateHeight;
                                                pdf.addPage();
                                            }
                                            if(
                                                (Math.floor(noCutRegions[i].offsetTopThisRow + noCutRegions[i].hWorkField)) >= (Math.floor(y + h))
                                            ) {
                                                if(
                                                    (Math.floor(yPdf + (noCutRegions[i].hWorkField) * rateHeight)) >= (Math.floor(pdfHeight - MARGIN * rateHeight))
                                                    && !isReachBadge
                                                )
                                                {
                                                    yPdf = MARGIN * rateHeight;
                                                    pdf.addPage();
                                                }
                                                isReachBadge = true;
                                            }
                                            canvas = getClippedRegion(finalImage, 0, y, finalImage.width, h);
                                            pdf.addImage(canvas, 'canvas', 0, yPdf, pdfWidth, hPdf);
                                            y += h;
                                            yPdf+= hPdf;
                                            DEBUG && document.body.appendChild(canvas) //For testing
                                        }
                                        canvas = getClippedRegion(finalImage, 0, y, finalImage.width, PADDING_ROW + BORDER_BOTTOM_EACH_ROW);
                                        pdf.addImage(canvas, 'canvas', 0, yPdf, pdfWidth, PADDING_ROW_PDF + BORDER_BOTTOM_EACH_ROW * rateHeight);
                                        DEBUG && document.body.appendChild(canvas) //For testing
                                        y+=PADDING_ROW + BORDER_BOTTOM_EACH_ROW;
                                        // Break on each area
                                        if(i + 1 === trArray.length) {
                                            //Cut remain
                                            const remainH = finalImage.height - y;
                                            const remainHPdf = remainH * rateHeight;
                                            if(
                                                Math.floor(yPdf + remainHPdf) >= Math.floor(pdfHeight - MARGIN * rateHeight)
                                            ) {
                                                yPdf = MARGIN * rateHeight;
                                                pdf.addPage();
                                            }
                                            canvas = getClippedRegion(finalImage, 0, y, finalImage.width, remainH);
                                            pdf.addImage(canvas, 'canvas', 0, yPdf, pdfWidth, remainHPdf);
                                            DEBUG && document.body.appendChild(canvas) //For testing
                                            break;
                                        }
                                        // yPdf = MARGIN * rateHeight;
                                        // pdf.addPage();
                                        yPdf+=PADDING_ROW_PDF + BORDER_BOTTOM_EACH_ROW * rateHeight;
                                        if(yPdf >= pdfHeight - MARGIN * rateHeight) {
                                            yPdf = MARGIN * rateHeight;
                                            pdf.addPage();
                                        }
                                    }
                                }
                                else {
                                    const canvas = getClippedRegion(finalImage, 0, 0, finalImage.width, finalImage.height);
                                    pdf.addImage(canvas, 'canvas', 0, 0, pdfWidth, finalImage.height * rateHeight);
                                }
                                !DEBUG && myDiv.parentNode.removeChild(myDiv);
                                pdf.save("chungchinanglucso.pdf");
                                dispatch(pageLoadingAction.hideFullScreenLoading())
                            }
                            finalImage.src = dataUrl;
                        })
                        .catch(error => {
                            dispatch(pageLoadingAction.hideFullScreenLoading())
                            showErrorMessage(
                                error?.message ||
                                    'Đã xảy ra lỗi!'
                            )
                        })
                    }
                }
            })
        }
        catch(error) {
            dispatch(pageLoadingAction.hideFullScreenLoading())
            showErrorMessage(
                error?.message ||
                    'Đã xảy ra lỗi!'
            )
        }
    }

    const handlePrintDocumentOnlyOnePage = (dataList) => {
        if(document.getElementById('myDiv'))document.getElementById('myDiv').parentNode.removeChild(document.getElementById('myDiv'))
        dispatch(pageLoadingAction.showFullScreenLoading())
        const DEBUG = false;
        const A4_PAGE_WIDTH = 1200;
        const PADDING_TABLE = 28;
        const PADDING_LR = 56;
        const MARGIN = 4;
        const PADDING_ROW = 16;
        const BORDER_BOTTOM_EACH_ROW = 1;
        const myDiv = document.createElement("div");
        myDiv.id = 'myDiv';
        myDiv.style.cssText = `background-color: #fff; padding: ${PADDING_TABLE}px ${PADDING_LR}px; width: ${A4_PAGE_WIDTH}px;`;
            myDiv.innerHTML = `<div id="header-pdf" class="header-pdf">
                                    <div id="info" class="info-pdf"
                                    >
                                        HỒ SƠ NĂNG LỰC SỐ: ${userData.fullName} - SN: ${convertStringToDateTimeString(userData.birthday, 'DD/MM/YYYY hh:mm:ss', 'DD/MM/YYYY') || ''}
                                    </div>
                                    <div class="header-right">
                                        <div class="col col-1">
                                            <img src="${logoUTE}" />
                                        </div>
                                        <div class="col col-2">
                                            <div>Địa chỉ: 1 Võ Văn Ngân, Phường Linh Chiểu, Quận Thủ Đức, Thành phố Hồ Chí Minh.</div>
                                            <div>Điện thoại: (+84 - 028) 38968641 - (+84 -028) 38961333 - (+84 -028) 37221223</div>
                                        </div>
                                    </div>
                                </div>`;
        const myTable = document.querySelectorAll(".digital-profile-table")[0].cloneNode(true);
        myDiv.append(myTable);
        myTable.style.cssText = "width: 100%; background: '#fff'";
        myTable.id = 'cloned-table';
        myTable.style.cssText = "max-width: 100%";
        //Add footer
        const footer = document.createElement('div');
        footer.innerHTML = `<div id="footer-pdf">
                                <img src="${logoUTE}" />
                                <img src="${logoNLU}" />
                                <img src="${logoIU}" />
                                <img src="${logoKHTN}" />
                                <img src="${logoHUFLIT}" />
                                <img src="${logoSPTPHCM}" />
                            </div>`;
        myDiv.appendChild(footer);
        document.querySelectorAll(".survey-profile-container")[0].appendChild(myDiv);
        let countLoading = 0;
        try {
            document.querySelectorAll("#myDiv img").forEach(img => {
                img.onload = function() {
                    countLoading++;
                    if(countLoading >= document.querySelectorAll("#myDiv img").length) {
                        domtoimage.toJpeg(myDiv, { bgcolor: '#fff' })
                        .then(function (dataUrl) {
                            const finalImage = document.createElement("img");
                            const pdf = new jsPDF('landscape', 'px', 'a4');
                            const pdfWidth = pdf.internal.pageSize.getWidth();
                            const pdfHeight = pdf.internal.pageSize.getHeight();
                            const FOOTER_HEIGHT = document.getElementById('footer-pdf').offsetHeight;
                            const HEIGHT_HEADER = PADDING_TABLE + document.getElementById('header-pdf').offsetHeight + document.querySelectorAll("#cloned-table .ant-table-thead")[0].offsetHeight;
                            const ratePdf = pdfHeight / pdfWidth;
                            finalImage.onload = function(){
                                const finalImageHeightEachPage = finalImage.width * ratePdf;
                                const rateHeight = pdfHeight / finalImageHeightEachPage;
                                if(finalImage.height * rateHeight > pdfHeight) {
                                    const canvas = getClippedRegion(finalImage, 0, 0, finalImage.width, finalImage.height);
                                    const fitPdfWidth = (finalImage.width / finalImage.height) * pdfHeight;
                                    pdf.addImage(canvas, 'canvas', (pdfWidth - fitPdfWidth) / 2, 0, fitPdfWidth, pdfHeight);
                                }
                                else {
                                    // TH verticle middle
                                    const canvas = getClippedRegion(finalImage, 0, 0, finalImage.width, finalImage.height);
                                    const fitPdfHeight = (finalImage.height / finalImage.width) * pdfWidth;
                                    pdf.addImage(canvas, 'canvas', 0, (pdfHeight - fitPdfHeight) / 2, pdfWidth, fitPdfHeight);

                                    // TH Footer luôn bottom
                                    // const footerH = FOOTER_HEIGHT + PADDING_TABLE;
                                    // const h = finalImage.height - footerH;
                                    // // Cắt mảng đầu không kèm footer
                                    // const hPdf = h * rateHeight;
                                    // const canvas = getClippedRegion(finalImage, 0, 0, finalImage.width, h);
                                    // pdf.addImage(canvas, 'canvas', 0, 0, pdfWidth, hPdf);
                                    // // Cắt mảng chứa footer
                                    // const footerHPdf = footerH * rateHeight;
                                    // const canvasFooter = getClippedRegion(finalImage, 0, h, finalImage.width, footerH);
                                    // pdf.addImage(canvasFooter, 'canvas', 0, pdfHeight - footerHPdf, pdfWidth, footerHPdf);
                                }
                                !DEBUG && myDiv.parentNode.removeChild(myDiv);
                                pdf.save("chungchinanglucso.pdf");
                                dispatch(pageLoadingAction.hideFullScreenLoading())
                            }
                            finalImage.src = dataUrl;
                        })
                        .catch(error => {
                            dispatch(pageLoadingAction.hideFullScreenLoading())
                            showErrorMessage(
                                error?.message ||
                                    'Đã xảy ra lỗi!'
                            )
                        })
                    }
                }
            })
        }
        catch(error) {
            dispatch(pageLoadingAction.hideFullScreenLoading())
            showErrorMessage(
                error?.message ||
                    'Đã xảy ra lỗi!'
            )
        }
    }

    return (
        <div className="survey-profile-container">
            {
                <DigitalProfileContent 
                    data={digitalProfile || {}}
                    listLoading={listLoading}
                    userData={userData}
                    handlePrintDocument={handlePrintDocumentOnlyOnePage}
                />
            }
        </div>
    )
}

export default DigitalProfile
