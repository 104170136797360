import { combineReducers } from 'redux'
import appCommon from './appCommon'
import account from './account'
import area from './area'
import aboutUs from './aboutUs'
import module from './module'
import test from './test'
import competences from './competences'

const rootReducer = combineReducers({
    appCommon: appCommon.reducer,
    account: account.reducer,
    area: area.reducer,
    aboutUs: aboutUs.reducer,
    module: module.reducer,
    test: test.reducer,
    competences: competences.reducer,
})

export default rootReducer
