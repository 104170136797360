import React, { useRef, useEffect } from 'react';

const TeacherSurveys = () => {
    const wrapperRef = useRef()
    const iframeRef = useRef()

    useEffect(() => {
        document.getElementById("root").style.overflowX = "hidden"
        const wrapperH = wrapperRef.current.clientHeight
        iframeRef.current.height = Math.floor(wrapperH / 1.25)

        return () => {
            document.getElementById("root").style.overflowX = ""
        }
    }, [])

    return (
        <div
        className='wrapper'
        ref={wrapperRef}
        >
            <iframe
            ref={iframeRef}
            src="https://docs.google.com/forms/d/e/1FAIpQLSdduW5t1zR9Wg9W8WkxVz-N-9ka5_00mcZJVR2iyxZMcxfWCQ/viewform?embedded=true"
            frameBorder="0" marginHeight="0" marginWidth="0">Đang tải…</iframe>
        </div>
    )
}

export default TeacherSurveys