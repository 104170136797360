import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom'
import { Button } from 'antd';
import { EyeFilled, QuestionCircleFilled, CheckOutlined } from '@ant-design/icons';
import BaseTable from '../common/table/BaseTable';
import { AppConstants } from '../../constants';
import logo from '../../assets/images/badge.png';
import Utils from '../../utils';
import { convertUtcToLocalTime } from '../../utils/datetimeHelper';

export default function PerformedTestContent({
    dataList,
    listLoading,
    handleTableChange,
    pagination,
    handleViewDetail,
    total,
}) {

    const history = useHistory();
    const pathname = history.location.pathname;

    const columns = useMemo(() => {
        return [
            {
              title: 'Huy hiệu',
              dataIndex: 'imagePath',
              width: 100,
              align: 'center',
              render: (imagePath) => (
                <img className="imagePath"
                    src={ imagePath ? `${AppConstants.contentRootUrl}/${imagePath}` : logo}
                />
              ),
            },
            {
                title: 'Lĩnh vực',
                render: (dataRow) => (
                    <div className="name">
                        {`${dataRow.surveyName}: ${dataRow.workFieldName}`}
                    </div>
                )
            },
            {
                title: 'Ngày bắt đầu',
                dataIndex: 'createDate',
                width: 150,
                align: 'left',
                render: (createDate) => {
                    return <div>{convertUtcToLocalTime(createDate, 'DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY (HH:mm)')}</div>
                }
            },
            {
              title: 'Trạng thái',
              width: 180,
              dataIndex: 'isFinish',
              align: 'left',
              render: (isFinish) => (
                <div>
                    {
                        !isFinish
                        ? <div><QuestionCircleFilled /> {'Chưa hoàn thành'}</div>
                        : <div><CheckOutlined style={{color: "#1890ff"}}/> {'Đã hoàn thành'}</div>
                    }
                </div>
              ),
            },
            {
                title: 'Chi tiết',
                width: 150,
                align:  'left',
                render: (dataRow) => {
                    return (
                        <span>
                            {
                                <Button type="link" onClick={() => {dataRow.isFinish ? handleViewDetail(dataRow) : handleViewDetail({...dataRow, id: -1})}} className="no-padding">
                                    <EyeFilled/> {'Xem chi tiết'}
                                </Button>
                            }
                        </span>
                    )
                }
            },
        ];
    }, [dataList])
    return (
        <div>
            <div className="title">Lịch sử các bài kiểm tra đã thực hiện</div>
            <BaseTable 
                className="base-table"
                size="middle"
                scroll={{ x: 800 }}
                loading={listLoading}
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={dataList || []}
                size="default"
                pagination={{
                    ...pagination,
                    total,
                    showSizeChanger: false,
                }}
                onChange={handleTableChange}
            />
        </div>
    )
}
