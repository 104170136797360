import { Layout } from 'antd'
import qs from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { actions } from '../../actions'
import TestPage from '../../components/test/TestPage'
import { QUESTION_TYPE } from '../../constants/index'
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../services/notifyService'
import { ensureArray } from '../../utils/helper'
import sitePathConfig from '../../constants/sitePathConfig'

const Test = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { moduleId } = qs.parse(history.location.search)
    const [examId, setExamId] = useState(-1)
    const [competencesData, setCompetencesData] = useState([false])
    const [currentSessionData, setCurrentSessionData] = useState({
        competenceIndex: -1,
        questionIndex: -1,
        totalQuestion: -1,
        currentQuestionCount: -1,
    })
    const [answerResult, setAnswerResult] = useState([])
    const [answerSubmitting, setAnswerSubmitting] = useState(false);
    const [areaName, setAreaName] = useState('')
    const [areaDescription, setAreaDescription] = useState({});

    const convertQuestion = ({ questionKind, questionContent, ...rest }) => {
        return {
            questionKind,
            questionContent: JSON.parse(questionContent),
            ...rest,
        }
    }

    const getQuestionByCompetence = (competence, examId, index) => {
        dispatch(
            actions.getListQuestionByCompetenceCombobox({
                params: {
                    competenceId: competence.id,
                    examId,
                },
                onCompleted: data => {
                    setCompetencesData(prev => {
                        const newData = [...prev]
                        newData[index] = {
                            ...competence,
                            questions: ensureArray(data).map(convertQuestion),
                        }

                        return newData
                    })
                },
                onError: error => {
                    showErrorMessage(
                        error?.message ||
                            'Đã xảy ra lỗi trong quá trình lấy dữ liệu!'
                    )
                },
            })
        )
    }

    const getCurrentQuestion = () => {
        const { competenceIndex, questionIndex } = currentSessionData
        if (
            competenceIndex === -1 ||
            questionIndex === -1 ||
            competencesData.some(competence => !competence) ||
            competenceIndex >= competencesData.length
        ) {
            return null
        }

        return competencesData[competenceIndex].questions[questionIndex]
    }

    const nextQuestion = () => {
        setCurrentSessionData(
            ({
                competenceIndex,
                questionIndex,
                totalQuestion,
                currentQuestionCount : prevQuestionCount,
            }) => {
                const currentQuestionCount = prevQuestionCount + 1
                if (
                    competencesData[competenceIndex].questions.length <=
                    questionIndex + 1
                ) {
                    return {
                        competenceIndex: competenceIndex + 1,
                        questionIndex: 0,
                        totalQuestion,
                        currentQuestionCount,
                    }
                } else {
                    return {
                        competenceIndex,
                        questionIndex: questionIndex + 1,
                        totalQuestion,
                        currentQuestionCount,
                    }
                }
            }
        )
    }

    const currentQuestion = getCurrentQuestion()

    const prepareDataAnswer = value => {
        const { answer } = value
        if (currentQuestion.questionKind === QUESTION_TYPE.TYPE5) {
            const arrAnswer = currentQuestion.questionContent.questions.map(
                (el, index) => ({
                    question: el.question,
                    answer: answer[index],
                })
            )
            return JSON.stringify(arrAnswer)
        }
        else if (currentQuestion.questionKind === QUESTION_TYPE.TYPE9) {
            const arrAnswer = currentQuestion.questionContent.map(
                (el, index) => ({
                    content: el.content,
                    answer: answer&&answer[index]?answer[index]:index===5?false:"",
                    require: el.require,
                })
            )
            return JSON.stringify(arrAnswer)
        }
        else if (currentQuestion.questionKind === QUESTION_TYPE.TYPE8) {
            return value ? JSON.stringify(value) : false;
        }
        else {
            return answer
        }
    }

    const finishExam = () => {
        dispatch(
            actions.finishExam({
                params: {
                    id: examId,
                },
                onCompleted: data => {
                    // TODO: handle finish exam success - redirect result page
                    history.push({
                        pathname: sitePathConfig.testResult.path,
                        state: {
                            areaName,
                            areaDescription,
                            competence: competencesData.map((com, indexCom) => {
                                const newCom = { ...com }
                                newCom.questions = com.questions.map((quest, index) => ({ ...quest, isRight: answerResult[indexCom]?.[index]?.isRight }))
                                return newCom
                            })
                        }
                    })
                },
                onError: error => {
                    showErrorMessage(
                        error?.message || 'Hoàn thành bài thi không thành công!'
                    )
                },
            })
        )
    }

    const handleSubmitAnswer = values => {
        const theAnswer = prepareDataAnswer(values)
        setAnswerSubmitting(true)

        dispatch(
            actions.examAnswer({
                params: {
                    examId,
                    questionId: currentQuestion?.id,
                    examQuestionId: currentQuestion?.examQuestionId,
                    theAnswer,
                },
                onError: error => {
                    setAnswerSubmitting(false)
                    showErrorMessage(
                        error.message || 'Kiểm tra đáp án không thành công'
                    )
                },
                onCompleted: value => {
                    setAnswerSubmitting(false)
                    // showSuccessMessage('Kiểm tra đáp án thành công')
                    const {
                        competenceIndex,
                        questionIndex,
                        currentQuestionCount,
                        totalQuestion,
                    } = currentSessionData
                    setAnswerResult(prev => {
                        const newData = [...prev]
                        newData[competenceIndex][questionIndex].isRight =
                            +value.data.result
                        return newData
                    })

                    // submit last question
                    if (currentQuestionCount + 1 >= totalQuestion) {
                        // redirect to result page
                        finishExam()
                    } else {
                        nextQuestion()
                    }
                },
            })
        )
    }

    useEffect(() => {
        dispatch(actions.showFullScreenLoading())
        dispatch(
            actions.getListCompetenceByModuleCombobox({
                params: {
                    moduleId,
                },
                onCompleted: data => {
                    const [competences, examId, area, areaDescriptionBasic, areaDescriptionMedium, areaDescriptionAdvanced] = data
                    setExamId(examId)
                    setAreaDescription({
                        areaDescriptionBasic,
                        areaDescriptionMedium,
                        areaDescriptionAdvanced,
                    })
                    setAreaName(area)
                    setCompetencesData(competences.map(_ => false))

                    for (let i = 0; i < competences.length; i++) {
                        getQuestionByCompetence(competences[i], examId, i)
                    }
                },
                onError: error =>
                    showErrorMessage(
                        error?.message ||
                            'Đã xảy ra lỗi trong quá trình lấy dữ liệu!'
                    ),
            })
        )
    }, [])

    useEffect(() => {
        if (competencesData.some(competence => !competence)) {
            return
        }

        dispatch(actions.hideFullScreenLoading())

        const findLastIndexQuestion = () => {
            // find last question not answer
            const totalQuestion = competencesData.reduce(
                (total, competence) => total + competence.questions.length,
                0
            )
            setAnswerResult(
                competencesData.map(com =>
                    com.questions.map(quest => ({ isRight: quest.isRight }))
                )
            )
            let currentQuestionCount = 0
            for (let iCom = 0; iCom < competencesData.length; iCom++) {
                const { questions } = competencesData[iCom]

                for (let iQuest = 0; iQuest < questions.length; iQuest++) {
                    const quest = questions[iQuest]
                    // not answer yet
                    if (quest.isRight === -1) {
                        return setCurrentSessionData({
                            competenceIndex: iCom,
                            questionIndex: iQuest,
                            totalQuestion,
                            currentQuestionCount,
                        })
                    }

                    currentQuestionCount++
                }
            }

            return setCurrentSessionData({
                competenceIndex: competencesData.length,
                questionIndex: 0,
                totalQuestion,
                currentQuestionCount,
            })
        }

        findLastIndexQuestion()
    }, [competencesData])

    const isDoneGetData = useMemo(
        () =>
            !!competencesData.length &&
            competencesData.every(competence => !!competence),
        [competencesData]
    )
    const { currentQuestionCount, totalQuestion } = currentSessionData
    const questionDonePercent = Math.round(
        (currentQuestionCount / totalQuestion) * 100
    )
    const allQuestionSubmitted = currentQuestionCount === totalQuestion

    return (
        <Layout className="test-page-container">
            <TestPage
                currentSessionData={currentSessionData}
                currentQuestion={currentQuestion}
                isDoneGetData={isDoneGetData}
                competencesData={competencesData}
                answerResult={answerResult}
                handleSubmitAnswer={handleSubmitAnswer}
                questionDonePercent={questionDonePercent}
                finishExam={finishExam}
                allQuestionSubmitted={allQuestionSubmitted}
                answerSubmitting={answerSubmitting}
                areaName={areaName}
            />
        </Layout>
    )
}

export default Test
