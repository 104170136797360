import { actionTypes, reduxUtil } from '../actions/aboutUs'

const initialState = {
    categoriesCombobox: [],
    listNewsStack: [],
    newsDetail: null,
    nlsDetail: null,
}

const reducer = reduxUtil.createReducer(
    {
        [reduxUtil.defineActionSuccess(actionTypes.GET_LIST_NEWS)]: (
            state,
            { data }
        ) => {
            return {
                ...state,
                listNewsStack: data,
            }
        },
        [reduxUtil.defineActionSuccess(actionTypes.GET_CATEGORY_COMBOBOX)]: (
            state,
            { data }
        ) => {
            return {
                ...state,
                categoriesCombobox: data
            }
        },
        [reduxUtil.defineActionSuccess(actionTypes.GET_NEWS_DETAIL)]: (
            state,
            { data, params }
        ) => {
            if(params.nlsDetail) {
                return {
                    ...state,
                    nlsDetail: data,
                }
            }
            else{
                return {
                    ...state,
                    newsDetail: data,
                }
            }
        },
    },
    initialState
)

export default {
    reducer,
}
