import React from 'react'
import { Tabs } from 'antd'
import PerformedTest from '../../containers/competences/PerformedTest'
import SurveyProfile from '../../containers/competences/SurveyProfile'
import DigitalProfile from '../../containers/competences/DigitalProfile'
const { TabPane } = Tabs

const CompetencesPage = ({
    handleSelectTab,
    activeKey,
}) => {
    return (
        <div className="competences-content mt-6">
            <Tabs 
                size="large" 
                type="card" 
                activeKey={activeKey}
                onTabClick={handleSelectTab}
            >
                <TabPane
                    className="p-2  competences-tab-content"
                    tab="Các bài kiểm tra đã thực hiện"
                    key="1"
                >
                    <PerformedTest />
                </TabPane>
                <TabPane
                    className="p-2  competences-tab-content"
                    tab="Hồ sơ năng lực số"
                    key="2"
                >
                    <DigitalProfile />
                </TabPane>
                {/* <TabPane
                    className="p-2  competences-tab-content"
                    tab="Hồ sơ năng lực số"
                    key="2"
                >
                    <SurveyProfile />
                </TabPane> */}
            </Tabs>
        </div>
    )
}

export default CompetencesPage
