import { createSelector } from 'reselect'

export const performedTestStackSelector = createSelector(
    [state => state.competences],
    competences => competences.performedTestStack
)

export const surveyProfileStackSelector = createSelector(
    [state => state.competences],
    competences => competences.surveyProfileStack
)

export const digitalProfileSelector = createSelector(
    [state => state.competences],
    competences => competences.digitalProfile
)
