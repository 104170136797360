import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { actions } from '../../actions/account'
import { actions as areaActions } from '../../actions/area'
import { actions as appCommonActions } from '../../actions/appCommon'

import RegisterForm from '../../components/account/RegisterForm'
import { AREA_KIND } from '../../constants'
import { sitePathConfig } from '../../constants/sitePathConfig'
import { userDataSelector } from '../../selectors/account'
import { provincesSelector, areasSelector, universitiesSelector, facultiesSelector, jobsSelector } from '../../selectors/area'
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../services/notifyService'
import { ensureArray } from '../../utils/helper'
import {
    convertDateTimeToString,
    convertStringToDateTime,
} from '../../utils/datetimeHelper'
import qs from 'query-string'

const Register = ({ isEditing }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const userData = useSelector(userDataSelector)
    // const provinces = useSelector(provincesSelector)
    // const areas = useSelector(areasSelector)
    const universities = useSelector(universitiesSelector)
    const faculties = useSelector(facultiesSelector)
    const jobs = useSelector(jobsSelector)
    // const [loadingProvince, setLoadingProvince] = useState(false)
    // const [loadingDistrict, setLoadingDistrict] = useState(false)
    // const [loadingWard, setLoadingWard] = useState(false)
    const [loadingUniversities, setLoadingUniversities] = useState(false)
    const [loadingFaculties, setLoadingFaculties] = useState(false)
    const [loadingJobs, setLoadingJobs] = useState(false)
    // const [provinceSelected, setProvinceSelected] = useState()
    // const [districtSelected, setDistrictSelected] = useState()

    const prepareSubmitData = values => {
        const data = {
            ...values,
            birthday: `${convertDateTimeToString(values.birthday)} 00:00:00`,
        }

        isEditing && (data.id = userData.id)

        return data
    }

    const onSubmit = valueForm => {
        const params = prepareSubmitData(valueForm)
        if (isEditing) {
            dispatch(
                actions.updateProfile({
                    params,
                    onCompleted: ({ message }) => {
                        showSuccessMessage(
                            message || 'Cập nhật hồ sơ thành công'
                        )
                    },
                    onError: error => {
                        showErrorMessage(
                            error?.message ||
                                'Cập nhật hồ sơ không thành công, vui lòng thử lại'
                        )
                    },
                })
            )
        } else {
            dispatch(
                actions.register({
                    params,
                    onCompleted: ({ data, message }) => {
                        showSuccessMessage(
                            message ||
                                'Đăng ký tài khoản thành công, vui lòng xác thực tài khoản để có thể sử dụng tài khoản.'
                        )
                        history.push({
                            pathname: sitePathConfig.verifyAccount.path,
                            search: qs.stringify({
                                email: data.email,
                                id: data.id,
                            }),
                        })
                    },
                    onError: error => {
                        showErrorMessage(
                            error?.message ||
                                'Đăng ký không thành công, vui lòng thử lại'
                        )
                    },
                })
            )
        }
    }

    const getDataDetailsMapping = () => {
        return {
            ...userData,
            // provinceId: userData.provinceDto?.provinceId,
            // districtId: userData.districtDto?.provinceId,
            // wardId: userData.wardDto?.provinceId,
            birthday: convertStringToDateTime(userData.birthday),
            universityId: userData.university?.categoryId,
            universityDeptId: userData.universityDept?.categoryId,
            jobId: userData.job?.categoryId
        }
    }

    // const getAreasByParentId = parentId => {
    //     return ensureArray(areas[parentId])
    // }

    // const setAreaSelect = (parentId, kind) => {
    //     if (kind === AREA_KIND.DISTRICT) {
    //         setProvinceSelected(parentId)
    //         setDistrictSelected('')
    //     } else {
    //         setDistrictSelected(parentId)
    //     }
    // }

    // const fetchAreasByParentId = (kind, parentId, first) => {
    //     if (areas[parentId]) {
    //         return setAreaSelect(parentId, kind)
    //     }

    //     const fetchAction = first
    //         ? areaActions.getAreaFirst
    //         : areaActions.getArea

    //     const loadingAction =
    //         kind === AREA_KIND.DISTRICT ? setLoadingDistrict : setLoadingWard

    //     loadingAction(prev => prev + 1)
    //     dispatch(
    //         fetchAction({
    //             params: {
    //                 parentId,
    //             },
    //             onDone: () => {
    //                 loadingAction(prev => Math.max(prev - 1, 0))
    //                 setAreaSelect(parentId, kind)
    //             },
    //         })
    //     )
    // }

    const uploadFile = payload => {
        dispatch(appCommonActions.uploadFile(payload))
    }

    const fetchFacultiesByParentId = parentId => {
        setLoadingFaculties(true)
        dispatch(
            areaActions.getUniversity({
                params: {
                    parentId,
                },
                onCompleted: () => setLoadingFaculties(false),
            })
        )
    }

    useEffect(() => {
        // if (provinces === null) {
        //     setLoadingProvince(true)
        //     dispatch(
        //         areaActions.getAreaFirst({
        //             onDone: () => setLoadingProvince(false),
        //         })
        //     )
        // }
        if (universities === null) {
            setLoadingUniversities(true)
            dispatch(
                areaActions.getUniversity({
                    params: {
                        kind: 2,
                    },
                    onCompleted: () => setLoadingUniversities(false),
                })
            )
        }
        if (jobs === null) {
            setLoadingJobs(true)
            dispatch(
                areaActions.getJob({
                    params: {
                        kind: 3,
                    },
                    onCompleted: () => setLoadingJobs(false),
                })
            )
        }
    }, [])

    useEffect(() => {
        if (isEditing && userData) {
            // userData.provinceDto?.provinceId &&
            //     fetchAreasByParentId(
            //         AREA_KIND.DISTRICT,
            //         userData.provinceDto?.provinceId,
            //         true
            //     )
            // userData.districtDto?.provinceId &&
            //     fetchAreasByParentId(
            //         AREA_KIND.WARD,
            //         userData.districtDto?.provinceId,
            //         true
            //     )
            userData.university?.categoryId &&
                fetchFacultiesByParentId(
                    userData.university?.categoryId,
                )
        }
    }, [userData])
    return (
        <div className="register-container">
            <div className="register-container-left">{isEditing ? 'Hồ sơ' : 'Đăng ký'}</div>
            <div className="register-container-right">
                <fieldset className="register-exclamation mt-4">
                    <legend><ExclamationCircleOutlined style={{color: 'red'}}/></legend>
                    Lưu ý: Tất cả các trường được đánh dấu ( * ) cần được điền để hỗ trợ thông tin cho quá trình phân tích dữ liệu và cấp chứng nhận năng lực số. Chúng tôi cam kết chỉ sử dụng thông tin cho mục đích nghiên cứu và đảm bảo bảo mật thông tin theo đạo đức nghiên cứu.
                </fieldset>
                <div className="container-content">
                    <h1 className="page-title">
                        {isEditing ? 'Cập nhật hồ sơ' : 'Đăng ký'}
                    </h1>
                    <div className="register-content">
                        <RegisterForm
                            formId="register-form"
                            dataDetail={getDataDetailsMapping()}
                            isEditing={isEditing}
                            onSubmit={onSubmit}
                            // loadingProvince={loadingProvince}
                            // loadingDistrict={loadingDistrict > 0}
                            // loadingWard={loadingWard > 0}
                            // getAreasByParentId={getAreasByParentId}
                            // fetchAreasByParentId={fetchAreasByParentId}
                            // provinces={ensureArray(provinces)}
                            // districts={ensureArray(areas[provinceSelected])}
                            // wards={ensureArray(areas[districtSelected])}
                            uploadFile={uploadFile}
                            universities={ensureArray(universities)}
                            faculties={ensureArray(faculties)}
                            jobs={ensureArray(jobs)}
                            loadingUniversities={loadingUniversities}
                            loadingFaculties={loadingFaculties}
                            fetchFacultiesByParentId={fetchFacultiesByParentId}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register
