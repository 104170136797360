import { actionTypes, reduxUtil } from '../actions/competences'

const initialState = {
    performedTestStack: [],
    surveyProfileStack: [],
    digitalProfile: {},
}

const reducer = reduxUtil.createReducer(
    {
        [reduxUtil.defineActionSuccess(actionTypes.GET_PERFORMED_TEST)]: (
            state,
            { data }
        ) => {
            return {
                ...state,
                performedTestStack: data,
            }
        },
        [reduxUtil.defineActionSuccess(actionTypes.GET_SURVEY_PROFILE_LIST)]: (
            state,
            { data }
        ) => {
            return {
                ...state,
                surveyProfileStack: data,
            }
        },
        [reduxUtil.defineActionSuccess(actionTypes.GET_DIGITAL_PROFILE_LIST)]: (
            state,
            { data }
        ) => {
            return {
                ...state,
                digitalProfile: data,
            }
        },
    },
    initialState
)

export default {
    reducer,
}
