export const sitePathConfig = {
    login: {
        path: '/login',
        title: 'Đăng nhập',
        layoutConfig: {
            contentClass: 'login-page',
        },
    },
    register: {
        path: '/register',
        title: 'Đăng ký',
    },
    updateProfile: {
        path: '/update-profile',
        title: 'Cập nhật hồ sơ điện tử',
    },
    homePage: {
        path: '/',
        title: 'Trang chủ',
    },
    aboutUs: {
        path: '/about',
        title: 'Về chúng tôi',
        layoutConfig: {
            contentClass: 'aboutUs-page',
        },
    },
    exams: {
        path: '/exams',
        title: 'Khảo sát SV',
    },
    teacherSurvey: {
        path: '/teacher-survey',
        title: 'Khảo sát GV',
        layoutConfig: {
            noAppTitle: true,
            contentClass: 'teacher-surveys-page',
        },
    },
    forbidden: {
        path: '/forbidden',
        title: 'Forbidden',
    },
    competences: {
        path: '/competences',
        title: 'Hồ sơ năng lực',
        layoutConfig: {
            contentClass: 'competences-page',
        },
    },
    verifyAccount: {
        path: '/verify-account',
        title: 'Xác thực tài khoản',
    },
    forgotPassword: {
        path: '/forgot-password',
        title: 'Quên mật khẩu',
    },
    preLogin: {
        path: '/prelogin',
        title: 'Trước khi trả lời câu hỏi',
        // layoutConfig: {
        //     contentClass: 'preLogin-page',
        // },
    },
    test: {
        path: '/test',
        title: 'Kiểm tra',
        layoutConfig: {
            contentClass: 'test-page',
            noAppTitle: true,
            noSupportMobile: true,
        },
    },
    testResult: {
        path: '/test-result',
        title: 'Kết quả bài kiểm tra',
        layoutConfig: {
            contentClass: 'test-result-page',
            noAppTitle: true,
        },
    },
    nlsPage: {
        path: '/nls-development',
        title: 'Phát triển NLS',
        layoutConfig: {
            contentClass: 'aboutUs-page',
        },
    },
    noSupport: {
        path: '/no-support',
        title: 'Không hỗ trợ',
    },
}

export default sitePathConfig
