import { createSelector } from 'reselect'

export const categoriesComboboxSelector = createSelector(
    [state => state.aboutUs],
    aboutUs => aboutUs.categoriesCombobox
)

export const listNewsStackSelector = createSelector(
    [state => state.aboutUs],
    aboutUs => aboutUs.listNewsStack
)

export const newsDetailSelector = createSelector(
    [state => state.aboutUs],
    aboutUs => aboutUs.newsDetail
)

export const nlsDetailSelector = createSelector(
    [state => state.aboutUs],
    aboutUs => aboutUs.nlsDetail
)