import React from 'react';
import { Link } from 'react-router-dom';
import { levels } from '../../constants/masterData';
import { Col, Image, Row} from 'antd'
import badge from '../../assets/images/entrepreneur-badge.png';
import camera from '../../assets/images/camera.png';
import desktopContent from '../../assets/images/desktop_content.png';
import logo from '../../assets/images/logo.png';

const TestResultPage = ({ competences, areaName, areaDescription, areaLevel }) => {
    return (
        <>
        <Row className="row">
            <Col span={8} order={1} className="col-1">
                <Image src={badge} style={{marginTop: '24px'}}/>
                <div class="congratulation" style={{marginTop: '1em'}}>
                    <h2><span style={{color:'#2e9dea'}}>
                        Chúc mừng!
                        </span></h2>
                    <p>
                        Bạn đã hoàn thành bài kiểm tra
                    </p>
                </div>
                <div className="comeback">
                    <div className="link digital-profile">
                        <Link to='/competences?activeKey=2'>
                            <img src={desktopContent} alt="" />
                            <div>Xem kết quả hồ sơ năng lực số</div>
                        </Link>
                    </div>
                    <div className="link another-module">
                        <Link to="/prelogin">
                            <img src={camera} alt="" />
                            <div>Tiếp tục một bài kiểm tra khác</div>
                        </Link>
                    </div>
                    <div className="link homepage">
                        <Link to="/about">
                            <img src={logo} alt="" />
                            <div>Về trang chủ</div>
                        </Link>
                    </div>
                </div>
            </Col>
            <Col span={16} order={2} className="col-2">
                <div className="title">
                    {areaName}
                </div>
                <div className="competence-list">
                    <div className="competence">
                        <div className="competence__level">
                            <div className="level-box">
                                {levels.map(level => (
                                    <div key={level.level} className={['level-item', areaLevel >= level.level && 'active'].filter(Boolean).join(' ')}>
                                        <div className="level-item__line" />
                                        {areaLevel === level.level && <div className="level-item__label">{level.label}</div>}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="competence__content">
                            {areaDescription}
                        </div>
                    </div>
                    {competences.map(competence => (
                        <div className="competence">
                            <div className="competence__name">
                                {competence.competenceName}
                            </div>
                            <div className="competence__level">
                                <div className="level-box">
                                    {levels.map(level => (
                                        <div className={['level-item', competence.level >= level.level && 'active'].filter(Boolean).join(' ')}>
                                            <div className="level-item__line" />
                                            {competence.level === level.level && <div className="level-item__label">{level.label}</div>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="competence__content">
                                {competence.description}
                            </div>
                        </div>
                    ))}
                </div>
            </Col>
        </Row>
        <div className="row--mb">
            <div className="link digital-profile">
                <Link to='/competences?activeKey=2'>
                    <img src={desktopContent} alt="" />
                    <div>Xem kết quả hồ sơ năng lực số</div>
                </Link>
            </div>
            <div className="link another-module">
                <Link to="/prelogin">
                    <img src={camera} alt="" />
                    <div>Tiếp tục một bài kiểm tra khác</div>
                </Link>
            </div>
            <div className="link homepage">
                <Link to="/">
                    <img src={logo} alt="" />
                    <div>Về trang chủ</div>
                </Link>
            </div>
        </div>
        </>
    );
};

export default TestResultPage;