import React, { useEffect } from 'react';
import { FormOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { Button, Col, Image, Progress, Row, Tag } from 'antd';
import badge from '../../assets/images/entrepreneur-badge.png';
import BaseQuestion from './BaseQuestion';
import CompetenceList from './CompetenceList';

import { AppConstants } from '../../constants/index';
import { QuestionRank } from '../../constants/masterData';

const TestPage = ({
    currentSessionData,
    currentQuestion,
    isDoneGetData,
    competencesData,
    answerResult,
    handleSubmitAnswer,
    questionDonePercent,
    finishExam,
    answerSubmitting,
    areaName,
}) => {
    const { competenceIndex } = currentSessionData

    useEffect(() => {
        const questElement = document.getElementById('question-info')
        if (currentQuestion && questElement) {
            document.getElementById('root').scrollTop = questElement.offsetTop - 14
        }
    }, [currentQuestion])

    if (!isDoneGetData) {
        return null
    }

    const questionLevel = QuestionRank.find(qRank=>qRank.value===currentQuestion?.questionRank);
    return (
        <Row>
            <Col span={24} xs={{ order: 2 }} lg={{ order: 1, span: 6 }}>
                <div className="competence-section">
                    <div className="text-center pt-4">
                        <Image src={badge} preview={false}/>
                    </div>
                    <div className="my-3">
                        <Progress
                            type="line"
                            showInfo={true}
                            percent={questionDonePercent}
                            strokeWidth={12}
                            strokeColor={{
                                '0%': '#2e9dea',
                                '100%': '#1a773c',
                            }}
                            className="progress-exam"
                        />
                    </div>
                    <div style={{
                        fontSize: 18,
                        textTransform: 'uppercase',
                        color: '#fff',
                        padding: '8px 16px',
                        margin: '12px 0',
                        fontVariant: 'tabular-nums',
                        fontWeight: 500,
                        listStyle: 'none',
                        backgroundColor: '#5B5B5F',
                        border: 'none',
                        borderBottom: 0,
                        borderRadius: 2,
                    }}>
                        {areaName.toUpperCase()}
                    </div>
                    <CompetenceList
                        competencesData={competencesData}
                        competenceIndex={competenceIndex}
                        answerResult={answerResult}
                    />
                </div>
            </Col>

            <Col span={24} xs={{ order: 1 }} lg={{ order: 2, span: 18 }} className="question-section">
                <div className="submit-exam-box p-4">
                    <Button
                        size="large"
                        className="btn-submit"
                        onClick={finishExam}
                    >
                        Hoàn thành
                    </Button>
                </div>

                {!!currentQuestion && (
                    <>
                        <fieldset id="question-info" className="question-info mt-4">
                            <legend><QuestionCircleFilled/></legend>
                                <div style={{
                                    fontWeight: 500,
                                    fontSize: 16
                                }}> <FormOutlined style={{marginRight: 8}}/> 
                                {
                                    questionLevel && <Tag color="#108ee9">{questionLevel.label.toUpperCase()}</Tag>
                                }
                                {currentQuestion.questionName || ''}</div>

                            {currentQuestion.questionContent?.url && (
                                <a
                                    className="mt-2"
                                    href={currentQuestion.questionContent?.url}
                                    target="_blank"
                                >
                                    {currentQuestion.questionContent?.url}
                                </a>
                            )}

                            {currentQuestion.questionAttachment && (
                                <div className="mt-2">
                                    <a
                                        href={`${AppConstants.contentRootUrl}${currentQuestion.questionAttachment}`}
                                        download="GFG"
                                        target="_blank"
                                    >
                                        <button type="button">
                                            Tải file đính kèm
                                        </button>
                                    </a>
                                </div>
                            )}
                        </fieldset>
                        <BaseQuestion
                            questionData={currentQuestion}
                            formName="form-answer"
                            handleSubmit={handleSubmitAnswer}
                            buttonSubmit={() =>
                                (<div className="text-left" style={{background: '#fff'}}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        form="form-answer"
                                        size="large"
                                        className="btn-submit-answer"
                                        loading={answerSubmitting}
                                        disabled={answerSubmitting}
                                    >
                                        Phản hồi
                                    </Button>
                                </div>)
                            }
                        />
                    </>
                )}
            </Col>
        </Row>
    )
}

export default TestPage
