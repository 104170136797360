import { call, takeLatest, takeEvery, put } from 'redux-saga/effects'

import { sendRequest } from '../services/apiService'
import { actionTypes, reduxUtil } from '../actions/competences'
import { actions } from '../actions'
import apiConfig from '../constants/apiConfig'
import { removeStorageItem, setStringData } from '../utils/localStorageHelper'
import { StorageKeys } from '../constants'
import { ensureArray } from '../utils/helper'

const { 
    GET_PERFORMED_TEST,
    GET_PERFORMED_TEST_DETAIL,
    GET_SURVEY_PROFILE_LIST,
    GET_COMPETENCE_PROFILE_LIST,
    GET_DIGITAL_PROFILE_LIST,
} = actionTypes

const { defineActionSuccess } = reduxUtil

function* getPerformedTest({ payload: { filter, params, onCompleted, onError, onDone } }) {
    try {
        const searchParams = {};
        if(filter){
            if(filter.size) searchParams.size = filter.size;
            if(filter.page || filter.page === 0) searchParams.page = filter.page;
        }

        const { success, responseData } = yield call(
            sendRequest,
            apiConfig.competences.getPerformedTestList,
            searchParams
        )

        if (success && responseData.result) {
            let data = [];
            const { stack, ...newParams} = params;
            if(stack){
                if(newParams.action === "PUSH"){
                    data = [
                        ...stack,
                        {
                        ...responseData.data,
                        ...newParams
                        }
                    ];
                }
                else if(newParams.action === "POP"){
                    data = [
                        {
                            ...responseData.data,
                            ...newParams
                        },
                        ...stack,
                    ];
                }
            }
            else{
                data = [
                    {
                        ...responseData.data,
                        ...newParams
                    }
                ];
            }
            onCompleted && onCompleted({
                ...responseData.data,
                ...newParams
            });
            yield put({
                type: defineActionSuccess(GET_PERFORMED_TEST),
                data,
            })
        } else {
            onError && onError(responseData)
        }
    } catch (error) {
        onError && onError(error)
    } finally {
        onDone && onDone()
    }
}

function* getPerformedTestDetail({ payload: { filter, params, onCompleted, onError } }) {
    try {
        const searchParams = {};
        let apiParams = {}, success = true, responseData = {
            data: [],
            result: true,
        }
        if(Number(filter.id) !== -1){
            if(filter){
                if(filter.size) searchParams.size = filter.size;
                if(filter.page || filter.page === 0) searchParams.page = filter.page;
            }
            apiParams = {
                ...apiConfig.competences.getPerformedTestDetail,
                path: `${apiConfig.competences.getPerformedTestDetail.path}/${filter.id}`
            }
            const result = yield call(sendRequest, apiParams, searchParams);
            success = result.success;
            responseData = result.responseData;
        }
        

        if (success && responseData.result) {
            let data = [];
            const { stack, ...newParams} = params;
            if(stack){
                if(newParams.action === "PUSH"){
                    data = [
                        ...stack,
                        {
                            ...responseData.data,
                            ...newParams,
                        }
                    ];
                }
                else if(newParams.action === "POP"){
                    data = [
                        {
                            ...responseData.data,
                            ...newParams,
                        },
                        ...stack,
                    ];
                }
            }
            else{
                data = [
                    {
                        ...responseData.data,
                        ...newParams,
                    }
                ];
            }
            onCompleted && onCompleted({
                ...responseData.data,
                ...newParams,
            });
            yield put({
                type: defineActionSuccess(GET_PERFORMED_TEST),
                data,
            })
        } else{
            onError && onError(responseData);
        }
    } catch (error) {
        onError && onError(error)
    }
}

function* getSurveyProfileList({ payload: { filter, params, onCompleted, onError, onDone } }) {
    try {
        const searchParams = {};
        if(filter){
            if(filter.size) searchParams.size = filter.size;
            if(filter.page || filter.page === 0) searchParams.page = filter.page;
        }

        const { success, responseData } = yield call(
            sendRequest,
            apiConfig.competences.getSurveyProfileList,
            searchParams
        )

        if (success && responseData.result) {
            let data = [];
            const { stack, ...newParams} = params;
            if(stack){
                if(newParams.action === "PUSH"){
                    data = [
                        ...stack,
                        {
                        ...responseData.data,
                        ...newParams
                        }
                    ];
                }
                else if(newParams.action === "POP"){
                    data = [
                        {
                            ...responseData.data,
                            ...newParams
                        },
                        ...stack,
                    ];
                }
            }
            else{
                data = [
                    {
                        ...responseData.data,
                        ...newParams
                    }
                ];
            }
            onCompleted && onCompleted({
                ...responseData.data,
                ...newParams
            });
            yield put({
                type: defineActionSuccess(GET_SURVEY_PROFILE_LIST),
                data,
            })
        } else {
            onError && onError(responseData)
        }
    } catch (error) {
        onError && onError(error)
    } finally {
        onDone && onDone()
    }
}

function* getCompetenceProfileList({ payload: { filter, params, onCompleted, onError, onDone } }) {
    try {
        const searchParams = {};
        if(filter){
            if(filter.size) searchParams.size = filter.size;
            if(filter.page || filter.page === 0) searchParams.page = filter.page;
            if(filter.surveyId || filter.surveyId === 0) searchParams.surveyId = filter.surveyId;
        }

        const { success, responseData } = yield call(
            sendRequest,
            apiConfig.competences.getCompetenceProfileList,
            searchParams
        )

        if (success && responseData.result) {
            let data = [];
            const { stack, ...newParams} = params;
            if(stack){
                if(newParams.action === "PUSH"){
                    data = [
                        ...stack,
                        {
                        ...responseData.data,
                        ...newParams
                        }
                    ];
                }
                else if(newParams.action === "POP"){
                    data = [
                        {
                            ...responseData.data,
                            ...newParams
                        },
                        ...stack,
                    ];
                }
            }
            else{
                data = [
                    {
                        ...responseData.data,
                        ...newParams
                    }
                ];
            }
            onCompleted && onCompleted({
                ...responseData.data,
                ...newParams
            });
            yield put({
                type: defineActionSuccess(GET_SURVEY_PROFILE_LIST),
                data,
            })
        } else {
            onError && onError(responseData)
        }
    } catch (error) {
        onError && onError(error)
    } finally {
        onDone && onDone()
    }
}

function* getDigitalProfileList({ payload: { filter, onCompleted, onError, onDone } }) {
    try {
        const searchParams = {};
        if(filter){
            if(filter.size) searchParams.size = filter.size;
            if(filter.page || filter.page === 0) searchParams.page = filter.page;
        }

        const { success, responseData } = yield call(
            sendRequest,
            apiConfig.competences.getDigitalProfileList,
            searchParams
        )

        if (success && responseData.result) {
            onCompleted && onCompleted(responseData.data);
            yield put({
                type: defineActionSuccess(GET_DIGITAL_PROFILE_LIST),
                data: responseData.data,
            })
        } else {
            onError && onError(responseData)
        }
    } catch (error) {
        onError && onError(error)
    } finally {
        onDone && onDone()
    }
}

const sagas = [
    takeLatest(GET_PERFORMED_TEST, getPerformedTest),
    takeLatest(GET_PERFORMED_TEST_DETAIL, getPerformedTestDetail),
    takeLatest(GET_SURVEY_PROFILE_LIST, getSurveyProfileList),
    takeLatest(GET_COMPETENCE_PROFILE_LIST, getCompetenceProfileList),
    takeLatest(GET_DIGITAL_PROFILE_LIST, getDigitalProfileList),
]

export default sagas
