import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import TestResultPage from '../../components/test/TestResultPage'
import { ANSWER_STATUS } from '../../constants';
import { mapLevelToDescription, mapLevelToAreaDescription, calcLevelFromPoint, getQuestionPoint } from '../../constants/masterData'

const TestResult = () => {

    const location = useLocation()

    const history = useHistory()

    const { competence, areaName, areaDescription } = location.state || {}

    !competence && history.push('/about')

    const validCompetenceData = () => {
        if (!competence) {
            return []
        }

        let totalOfResult = 0;
        const result = competence.map(com => {
            const totalPoint = com.questions.reduce((total, quest) => total + getQuestionPoint(quest), 0)
            const level = calcLevelFromPoint(totalPoint)
            totalOfResult += totalPoint
            return {
                level,
                competenceName: com.competencesName,
                description: level ? com[mapLevelToDescription[level]] : 'Bắt đầu'
            }
        });
        const areaLevel = calcLevelFromPoint(totalOfResult/competence.length);
        return {
            competences: result,
            areaLevel,
            areaDescription: areaLevel ? areaDescription[mapLevelToAreaDescription[areaLevel]] : 'Bắt đầu',
        }
    }
    const dataResult = validCompetenceData();
    return competence && (
        <div className="test-result">
            <TestResultPage competences={dataResult.competences} areaName={areaName} areaDescription={dataResult.areaDescription} areaLevel={dataResult.areaLevel}/>
        </div>
    ) || <div></div>;
};

export default TestResult;