import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

class NoSupport extends Component {

    render() {
        return (
            <div className="page-not-found">
                <div className="desc">
                    Hệ thống không hỗ trợ làm bài trên mobile, vui lòng làm bài trên máy tính.
                </div>
                <Link to="/"><Button className="go-back-btn" type="primary" size="large">Quay về</Button></Link>
            </div>
        );
    }
}

export default NoSupport;
