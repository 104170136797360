import React, { useRef } from 'react'
import { Radio, Card, Form, Row, Col, Image } from 'antd'
import BaseTable from '../../../components/common/table/BaseTable'
import { AppConstants } from '../../../constants'

const ChooseAnswer = ({ questionData, handleSubmit, formName, buttonSubmit }) => {
    const formRef = useRef();
    const columns = [
        {
            align: 'center',
            width: '10px',
            dataIndex: 'content',
            render: content => <Radio value={content} style={{margin: 0, marginLeft: 4}}/>,
        },
        {
            dataIndex: 'content',
            className: 'col-content',
            render: content => (
                <div style={{cursor: 'pointer', padding: '16px 4px'}} onClick={()=>formRef.current.setFieldsValue({['answer']: content})}>{content}</div>
            )
        },
    ]

    const { questionContent } = questionData
    return (
        <Form
            ref={formRef}
            name={formName}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{}}
            onFinish={handleSubmit}
        >
            <Card>
                <Row gutter={16}>
                    <Col span={24} md={{ order: 2, span: 8 }}>
                        <div className="py-8 px-6">
                            <Image width="100%" src={AppConstants.contentRootUrl + questionData.questionAvatar} alt="image-question" />
                        </div>
                    </Col>
                    <Col span={24} md={{ order: 1, span: 16 }}>
                        <div>
                            <b>Danh sách câu trả lời</b>
                        </div>
                        <Form.Item
                            name="answer"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn một đáp án!',
                                },
                            ]}
                            wrapperCol={{
                                span: 24,
                            }}
                            className="mb-0"
                        >
                            <Radio.Group className="mt-4" style={{ width: '100%' }}>
                                <BaseTable
                                    columns={columns}
                                    dataSource={questionContent.map((el, index) => ({ ...el, key: index }))}
                                    pagination={false}
                                    showHeader={false}
                                />
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                {buttonSubmit()}
            </Card>
        </Form>
    )
}

export default ChooseAnswer
