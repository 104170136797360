import { actionTypes, reduxUtil } from '../actions/test'

const initialState = {
    listCompetenceByModuleCombobox: [],
    listQuestionByCompetenceCombobox: [],
}

const reducer = reduxUtil.createReducer(
    {
        [reduxUtil.defineActionSuccess(actionTypes.GET_LIST_COMPETENCE_BY_MODULE_COMBOBOX)]: (
            state,
            { data, examId }
        ) => {
            return {
                ...state,
                listCompetenceByModuleCombobox: data,
                examId: examId,
            }
        },
    },
    {
        [reduxUtil.defineActionSuccess(actionTypes.GET_LIST_QUESTION_BY_COMPETENCE_COMBOBOX)]: (
            state,
            { data }
        ) => {
            return {
                ...state,
                listQuestionByCompetenceCombobox: data,
            }
        },
    },
    {
        [reduxUtil.defineActionSuccess(actionTypes.EXAM_ANSWER)]: (
            state,
            { data }
        ) => ({
            ...state,
            data: {
                ...state.data,
                ...data,
            },
        })
    },
    initialState
)

export default {
    reducer,
}
