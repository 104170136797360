import React, { useRef } from 'react';
import { Radio, Card, Form, Row, Col, Input } from 'antd'
import BaseTable from '../../../components/common/table/BaseTable'
import FileUploadField from '../../common/entryForm/FileUploadField'
const { TextArea } = Input;
const EmailQuestion = ({ questionData, handleSubmit, formName, buttonSubmit }) => {
    const [ form ] = Form.useForm();
    const refSubmitted = useRef(false)

    const handleChangeAnswer = (indexRow, indexCol) => {
        const answer = form.getFieldValue('answer') || []
        answer[indexRow.key] = indexCol
        form.setFieldsValue({ answer });
        refSubmitted.current && form.validateFields(['answer'])
    }

    const columns =  [
        {
            dataIndex: 'content',
            className: 'col-content',
            render: content => (
                <div style={{cursor: 'pointer', padding: 16}}>{content}</div>
            )
        },
        {
            dataIndex: 'answer',
            render: (answer, key) => 
                key.content==="Attachment"?
                <FileUploadField onChange={(value)=>handleChangeAnswer(key,true)}></FileUploadField>:
                key.content==="Message"?
                <TextArea rows={4} style={{maxHeight:300}} onChange={(value)=>handleChangeAnswer(key,value.currentTarget.value)} required = {key.require}/>:
                <Input onChange={(value)=>handleChangeAnswer(key,value.currentTarget.value)} required = {key.require}/>

        },
    ]
    const { questionContent } = questionData
    return (
       <Form
            form={form}
            name={formName}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{}}
            onFinish={values => {
                refSubmitted.current = true
                handleSubmit(values)
            }}
        >
            <Card size="small"  style={{ width: '100%', marginBottom: '4px' }}>
                <div>
                    Danh sách câu hỏi
                </div>
                <Form.Item
                    name="answer"
                    wrapperCol={{ span: 24 }}
                >
                    <BaseTable
                        columns={columns}
                        dataSource={questionContent.map((el, index) => ({ ...el, key: index }))}
                        pagination={false}
                    />
                </Form.Item>
                {buttonSubmit()}
            </Card>
       </Form>
    )
};

export default EmailQuestion;