import { all } from 'redux-saga/effects'
import appCommon from './appCommon'
import account from './account'
import area from './area'
import aboutUs from './aboutUs'
import module from './module'
import test from './test'
import competences from './competences'

const sagas = [
    ...appCommon, 
    ...account, 
    ...area,
    ...aboutUs,
    ...module,
    ...test,
    ...competences,
]

function* rootSaga() {
    yield all(sagas)
}

export default rootSaga
