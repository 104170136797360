import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'antd';
import NewsMeta from './NewsMeta';
import { actions } from '../../actions/aboutUs';
import { newsDetailSelector } from '../../selectors/aboutUs';
import { showErrorMessage } from '../../services/notifyService';
import { NewsId } from '../../constants';
import '../../styles/quill.snow.css'; // ES6

export default function AboutUsDetail(props) {
    const dispatch = useDispatch()
    const newsDetail = useSelector(newsDetailSelector)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if(!newsDetail) {
            setLoading(true)
            dispatch(
                actions.getNewsDetail({
                    params: { id: NewsId },
                    onCompleted: responseData => {
                        setLoading(false)
                    },
                    onError: error => {
                        setLoading(false)
                        showErrorMessage(
                            error?.message ||
                                'Đã xảy ra lỗi trong quá trình lấy dữ liệu!'
                        )
                    },
                })
            )
        }
    }, [])
    return (
        <div className="aboutUs-page">
            <main>
                <div className="detail-news">
                    <List loading={loading} style={{padding: 0}}>
                        {
                            newsDetail && (<>
                                    {/* <NewsMeta item={newsDetail}/> */}
                                    <div className="new-content ql-editor" dangerouslySetInnerHTML={{ __html: newsDetail?.newContent}} ></div>
                                </>
                            )
                        }
                    </List>
                </div>
            </main>
        </div>
    )
}
