import { call, takeLatest, put } from 'redux-saga/effects'

import { sendRequest } from '../services/apiService'
import { actionTypes, reduxUtil } from '../actions/module'
import apiConfig from '../constants/apiConfig'
import { ensureArray } from '../utils/helper'

const { GET_MODULE_COMBOBOX } = actionTypes
const DEFAULT_TABLE_ITEM_SIZE = 2;
const { defineActionSuccess } = reduxUtil

function* getModuleCombobox({ payload: { onCompleted, onError } }) {
    try {
        const { success, responseData } = yield call(
            sendRequest,
            apiConfig.module.moduleCombobox
        )
        if (success && responseData.result) {
            onCompleted && onCompleted(responseData.data?.data)
            yield put({
                type: defineActionSuccess(GET_MODULE_COMBOBOX),
                data: ensureArray(responseData.data?.data),
            })
        } else {
            onError && onError(responseData)
        }
    } catch (error) {
        onError && onError(error)
    }
}


const sagas = [
    takeLatest(GET_MODULE_COMBOBOX, getModuleCombobox),
]

export default sagas
