import reduxHelper from '../utils/redux'

export const reduxUtil = reduxHelper('MODULE')

const { defineAction, createAction } = reduxUtil

export const actionTypes = {
    GET_MODULE_COMBOBOX: defineAction('GET_MODULE_COMBOBOX'),
    
}

export const actions = {
    getListModule: createAction(actionTypes.GET_MODULE_COMBOBOX),
}
